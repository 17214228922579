// import ReactGA from "react-ga4";
// import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import LandingPage from "../components/Landing/LandingPage";
import LoginPage from "../components/Login/LoginPage";
import SfvPage from "../components/SfvPage/SfvPage";
import ThemeComponent from "../themes/ThemeComponent";
import ProjectAppLayout from "../layouts/ProjectAppLayout";
import MobileAppLayout from "../layouts/MobileAppLayout";
import ProjectEditLayout from "../layouts/ProjectEditLayout";
import QrPage from "../components/Mobile/QrPage";
import QrScanPage from "../components/Mobile/QrPage/QrScanPage";
import MobileMainPage from "../components/Mobile/MobileMainPage";
import ChecklistPage from "../components/Mobile/ChecklistPage";

import UsersPage from "../components/Users/UserPage";
import ProjectPage from "../components/Projects/ProjectPage";
import MachineStatusPage from "../components/Projects/Edit/MachineStatus";
import StatusMasterPage from "../components/Projects/Edit/StatusMaster";
import FileManagementPage from "../components/Projects/Edit/FileManagement";
import RiskAssessmentMain from "../components/RISK_ASSESSMENT/pages/main";
import StandardRegister from "../components/RISK_ASSESSMENT/pages/standard/register";
import StandardLaw from "../components/RISK_ASSESSMENT/pages/standard/law";
import StandardCause from "../components/RISK_ASSESSMENT/pages/standard/cause";
import StandardMchInfo from "../components/RISK_ASSESSMENT/pages/standard/mchInfo";
import StandardCalculation from "../components/RISK_ASSESSMENT/pages/standard/calculation";
import StandardScenario from "../components/RISK_ASSESSMENT/pages/standard/scenario";

function AllRoute() {
  // const location = useLocation();

  // Google Analytics 설정
  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: location.pathname });
  // }, [location]);

  const darkSettings = { mode: "dark" };
  const lightSettings = { mode: "light" };

  return (
    <Routes>
      <Route 
        path="/" 
        element={
          <ThemeComponent settings={darkSettings}>
            <LandingPage />
          </ThemeComponent>
        } 
      />

      {/* 그룹별 로그인 페이지 */}
      <Route
        path="/login/:groupcode"
        element={
          <ThemeComponent settings={darkSettings}>
            <LoginPage />
          </ThemeComponent>
        }
      />

      {/* /projects 경로 */}
      <Route
        path="/projects"
        element={
          <ThemeComponent settings={lightSettings}>
            <ProtectedRoute>
              <ProjectAppLayout />
            </ProtectedRoute>
          </ThemeComponent>
        }
      >
        {/* /projects 기본 페이지 */}
        <Route index element={<ProjectPage />} />
      </Route>

      {/* 위험성 평가 시스템 라우트 */}
      <Route
        path="/safe/:group_code/:project_code"
        element={
          <ThemeComponent settings={lightSettings}>
            <ProtectedRoute>
              <RiskAssessmentMain />
            </ProtectedRoute>
          </ThemeComponent>
        }
      />

      <Route
        path="/safe/:group_code/:project_code/standard/register"
        element={
          <ProtectedRoute>
            <StandardRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/safe/:group_code/:project_code/standard/law"
        element={
          <ProtectedRoute>
            <StandardLaw />
          </ProtectedRoute>
        }
      />
      <Route
        path="/safe/:group_code/:project_code/standard/cause"
        element={
          <ProtectedRoute>
            <StandardCause />
          </ProtectedRoute>
        }
      />
      <Route
        path="/safe/:group_code/:project_code/standard/mch-info"
        element={
          <ProtectedRoute>
            <StandardMchInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/safe/:group_code/:project_code/standard/calculation"
        element={
          <ProtectedRoute>
            <StandardCalculation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/safe/:group_code/:project_code/standard/scenario"
        element={
          <ProtectedRoute>
            <StandardScenario />
          </ProtectedRoute>
        }
      />

      {/* /users 경로 */}
      <Route
        path="/users"
        element={
          <ThemeComponent settings={lightSettings}>
            <ProtectedRoute>
              <ProjectAppLayout />
            </ProtectedRoute>
          </ThemeComponent>
        }
      >
        <Route index element={<UsersPage />} />
      </Route>

      {/* DT 화면 조회 */}
      <Route
        path="/insightwin/:group_code/:project_code"
        element={
          <ThemeComponent settings={darkSettings}>
            <ProtectedRoute>
              <SfvPage />
            </ProtectedRoute>
          </ThemeComponent>
        }
      />

      {/* 프로젝트 에디트 */}
      <Route
        path="/edit"
        element={
          <ThemeComponent settings={lightSettings}>
            <ProtectedRoute>
              <ProjectEditLayout />
            </ProtectedRoute>
          </ThemeComponent>
        }
      >
        <Route
          path="file/:group_code/:project_code"
          element={<FileManagementPage />}
        />
        <Route
          path="machinestatus/:group_code/:project_code"
          element={<MachineStatusPage />}
        />
        <Route
          path="statusmaster/:group_code/:project_code"
          element={<StatusMasterPage />}
        />
      </Route>

      {/* /mobile의 하위 페이지 */}
      <Route
        path="/mobile"
        element={
          <ThemeComponent settings={lightSettings}>
            <ProtectedRoute>
              <MobileAppLayout />
            </ProtectedRoute>
          </ThemeComponent>
        }
      >
        <Route index element={<MobileMainPage />} />
        <Route path="qr" element={<QrPage />} />
        <Route path="qrscan" element={<QrScanPage />} />
        <Route path="checklist" element={<ChecklistPage />} />
      </Route>

      {/* 기타 경로는 랜딩 페이지로 리다이렉트 */}
      <Route path="*" element={<LandingPage />} />
    </Routes>
  );
}

export default AllRoute;
