import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, useMediaQuery, useTheme } from '@mui/material';

const MobileAppLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleMenuClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* AppBar를 상단에 고정 */}
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h4" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'white' }}>
            설비사후보전
          </Typography>
        </Toolbar>
      </AppBar>

      {/* AppBar와 콘텐츠 사이의 여백(마진)을 추가 */}
      <Toolbar />

      {/* 하위 콘텐츠 영역에 스크롤을 적용 */}
      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default MobileAppLayout;