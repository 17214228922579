import { useState } from "react";
import { useGetTitle } from "../../../hooks/useGetTitle";
import { useParams } from "react-router-dom";
import Header from "../../../components/Layout/Header";
import TableComponent from "../../../components/common/SingleTableComponent";
import { standardRegisterColumns } from "../../../constants/columns";
import ReusableTable from "../../../components/Table/ReusableTable";
import styles from "../../main/main.module.css";

function StandardRegister() {
  const { group_code, project_code } = useParams();
  const titleInfo = useGetTitle();
  const { title, subTitle } = titleInfo;
  const [updateStatus, setUpdateStatus] = useState(false);
  const displayModeObject = {
    createDisplayMode: "modal",
    editDisplayMode: "modal",
  };
  const buttons = [
    {
      text: "추가",
      variant: "add",
      type: "create",
    },
    {
      text: "삭제",
      variant: "delete",
      type: "delete",
    },
  ];

  return (
    <>
      <Header group_code={group_code} project_code={project_code} />
      <TableComponent title={title} subTitle={subTitle}>
        <ReusableTable
          updateStatus={updateStatus}
          columns={standardRegisterColumns}
          group_code={group_code}
          project_code={project_code}
          baseUrl={[
            "/getSafeProcessList",
            "/postSafeProcess",
            "/updateSafeProcess",
            "/deleteSafeProcess",
          ]}
          buttons={buttons}
          title="위험성평가 대상 공정등록"
          displayConfigObj={displayModeObject}
          exlucdColumns={["process_id"]}
          boxWidth="50vw"
          isRowEdit={true}
          deleteParam="process_id"
        />
      </TableComponent>
    </>
  );
}

export default StandardRegister;
