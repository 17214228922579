import React from "react";

function Pen01(props) {
  return (
    <svg
      height="15"
      width="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#adadad" strokeLinecap="butt" strokeLinejoin="miter">
        <path
          d="M1.87 10.62l2.5 2.5"
          fill="none"
          stroke="#adadad"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M12.18 5.31l-2.5-2.5"
          fill="none"
          stroke="#adadad"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M4.37 13.12l-3.12 0.63 0.62-3.13 9.69-9.68 2.5 2.5z"
          fill="none"
          stroke="#adadad"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default Pen01;
