import { useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import { useProjectApi } from "./useProjectApi";

export const useSelectData = (
  selectFetchers,
  updateStatus,
  groupCode,
  projectCode
) => {
  const api = useProjectApi(groupCode, projectCode);
  const queries = Object.entries(selectFetchers).map(([key, config]) => ({
    queryKey: [updateStatus, config.url],
    queryFn: async () => {
      const response = await api.get(config.url);
      return response.data;
    },
    enabled: !!config.url,
    placeholderData: [],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
  }));
  const results = useQueries({ queries });
  return useMemo(() => {
    const data = {};
    Object.keys(selectFetchers).forEach((key, index) => {
      data[key] = results[index].data || [];
    });
    return data;
  }, [selectFetchers, results]);
};
