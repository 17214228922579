import { useState } from "react";
import { useGetTitle } from "../../../hooks/useGetTitle";
import { useParams } from "react-router-dom";
import Header from "../../../components/Layout/Header";
import TableComponent from "../../../components/common/SingleTableComponent";
import { standardCauseColumns } from "../../../constants/columns";
import ReusableTable from "../../../components/Table/ReusableTable";

function StandardCause() {
  const { group_code, project_code } = useParams();
  console.log(group_code, project_code);
  const titleInfo = useGetTitle();
  const { title, subTitle } = titleInfo;
  const [updateStatus, setUpdateStatus] = useState(false);
  const displayModeObject = {
    createDisplayMode: "modal",
    editDisplayMode: "modal",
  };
  const buttons = [
    {
      text: "추가",
      variant: "add",
      type: "create",
    },
    {
      text: "삭제",
      variant: "delete",
      type: "delete",
    },
  ];
  return (
    <>
      <Header group_code={group_code} project_code={project_code} />
      <TableComponent title={title} subTitle={subTitle}>
        <ReusableTable
          columns={standardCauseColumns}
          group_code={group_code}
          project_code={project_code}
          baseUrl={[
            "/getSafeSituation",
            "/postSafeSituation",
            "/putSafeSituation",
            "/deleteSafeSituation",
          ]}
          title="위험발생상황 및 결과"
          displayConfigObj={displayModeObject}
          buttons={buttons}
          isRowEdit={true}
          boxWidth="65vw"
          deleteParam="danger_cause_id"
        />
      </TableComponent>
    </>
  );
}

export default StandardCause;
