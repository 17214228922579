import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useProjectApi } from "../../hooks/useProjectApi";
import * as message from "../../constants/messages";

export const useCreateTableData = (
  updateStatus,
  endpoint,
  groupCode,
  projectCode
) => {
  const queryClient = useQueryClient();
  const api = useProjectApi(groupCode, projectCode);
  return useMutation({
    mutationKey: [updateStatus, endpoint],
    mutationFn: async (arr) => {
      console.log("mutation에 전달된 데이터:", arr);
      console.log("엔드포인트:", endpoint);
      const response = await api.post(endpoint, arr);
      console.log("서버 응답:", response);
      if (response.status !== 200) {
        throw new Error("Failed to add sth");
      }
      return response.status;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === updateStatus,
      });
      await queryClient.refetchQueries({
        predicate: (query) => query.queryKey[0] === updateStatus,
        type: "active",
      });
      toast.success(message.completeAdd);
    },
    onError: () => {
      toast.error(message.errorCreate);
    },
  });
};

export const useGetTableData = (
  updateStatus,
  endpoint,
  groupCode,
  projectCode,
  dataName = "data"
) => {
  const api = useProjectApi(groupCode, projectCode);
  const { data, ...rest } = useQuery({
    queryKey: [updateStatus, endpoint],
    queryFn: async () => {
      const response = await api.get(endpoint);
      return response.data;
    },
    placeholderData: [],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
  });
  return { [dataName]: data || [], ...rest };
};

export const useDeleteTableData = (
  updateStatus,
  baseEndpoint,
  groupCode,
  projectCode
) => {
  const queryClient = useQueryClient();
  const api = useProjectApi(groupCode, projectCode);
  return useMutation({
    mutationKey: [updateStatus, baseEndpoint],
    mutationFn: async ({ deleteParam, param }) => {
      const endpoint = `${baseEndpoint}?${deleteParam}=${param}`;
      const response = await api.delete(endpoint);
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [updateStatus],
        exact: true,
        refetchType: "all",
      });
      await queryClient.refetchQueries([updateStatus]);
      toast.success(message.deleteSuccess);
    },
    onError: (error) => {
      console.error("삭제 중 오류 발생:", error);
      toast.error(message.deleteError);
    },
  });
};

export const usePutTableData = (
  updateStatus,
  endpoint,
  groupCode,
  projectCode
) => {
  const queryClient = useQueryClient();
  const api = useProjectApi(groupCode, projectCode);
  return useMutation({
    mutationKey: [updateStatus, endpoint],
    mutationFn: async (arr) => {
      const response = await api.put(endpoint, arr);
      if (response.status !== 200) {
        throw new Error("Failed to edit sth");
      }
      return response.status;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([updateStatus]);
      toast.success(message.editComplete);
    },
    onError: (error) => {
      console.log(error);
      toast.error(message.editError);
    },
  });
};

export const usePutTableDataReset = (
  updateStatus,
  endpoint,
  groupCode,
  projectCode
) => {
  const queryClient = useQueryClient();
  const api = useProjectApi(groupCode, projectCode);

  return useMutation({
    mutationKey: [updateStatus, endpoint],
    mutationFn: async (arr) => {
      const response = await api.put(endpoint, arr);
      if (response.status !== 200) {
        throw new Error("Failed to edit sth");
      }
      return response.status;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([updateStatus]);
    },
    onError: (error) => {
      console.log(error);
      toast.error(message.resetError);
    },
  });
};
