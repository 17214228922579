import styled from "styled-components";
import SelectBox from "./SelectBox";
import { COLORS } from "../../styles/colors";
import { menuItems } from "../../constants/menuItem";
import { useLocation } from "react-router-dom";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

const ContentWrapper = styled.div`
  width: 85vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const TitleBox = styled.div`
  width: 70%;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
  border: 1px solid ${COLORS.TABLE_BORDER};
  background-color: ${COLORS.BACKGROUND};
`;

const TitleContent = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  align-items: flex-end;
  justify-content: flex-start;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: Pretendard, sans-serif;
  font-size: 1.875rem;
  font-weight: 600;
  color: ${COLORS.PRIMARY};
  display: flex;
  align-items: center;
`;

const SubTitle = styled.div`
  font-family: Pretendard !important;
  font-size: 1.125rem;
  color: ${COLORS.BACKGROUND_DARKER};
  line-height: 1.5;
`;

const BreadCrumbBox = styled.div`
  padding: 8px 24px;
  border-top: 1px solid ${COLORS.TABLE_BORDER};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const BreadCrumb = styled.div`
  font-size: 0.75rem;
  color: ${COLORS.BACKGROUND_DARK};
  text-align: right;
  opacity: 0.85;
`;

const SelectBoxContainer = styled.div`
  display: flex;
  gap: 8px;
  ${(props) => props.wrapperClass}
`;

const TableContainer = styled.div`
  border-radius: 8px;
  border: 0;
  display: ${(props) => props.$display};
  gap: ${(props) => props.$display === "flex" && "1rem"};
  ${(props) => props.tableWidth}
`;

const TableComponent = ({
  title,
  subTitle,
  selectBoxProps,
  wrapperClass,
  tableShow = true,
  tableWidth,
  $display = "block",
  children,
}) => {
  const location = useLocation();
  const getBreadcrumbText = () => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const mainMenu = menuItems.find((item) =>
      item.path.includes(pathSegments[pathSegments.length - 2])
    );
    const subMenu = mainMenu?.subItems.find((item) =>
      item.path.includes(pathSegments[pathSegments.length - 1])
    );
    if (mainMenu && subMenu) {
      return `현재 위치 : 위험성 평가 시스템 > ${mainMenu.title} > ${subMenu.title}`;
    }
    return "현재 위치 : 위험성 평가 시스템";
  };
  return (
    <PageContainer>
      <ContentWrapper>
        <TitleBox>
          <TitleContent>
            <TitleSection>
              <Title>{title}</Title>
            </TitleSection>
            <SubTitle>{subTitle}</SubTitle>
          </TitleContent>
          <BreadCrumbBox>
            <BreadCrumb>{getBreadcrumbText()}</BreadCrumb>
          </BreadCrumbBox>
        </TitleBox>
        {selectBoxProps && selectBoxProps.length > 0 && (
          <SelectBoxContainer wrapperClass={wrapperClass}>
            {selectBoxProps.map((props, index) => (
              <SelectBox key={`select-box-${index}`} {...props} />
            ))}
          </SelectBoxContainer>
        )}
        {tableShow && (
          <TableContainer $display={$display} tableWidth={tableWidth}>
            {children}
          </TableContainer>
        )}
      </ContentWrapper>
    </PageContainer>
  );
};

export default TableComponent;
