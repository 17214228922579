import { MaterialReactTable } from "material-react-table";
import { Box, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../common/Button";
import {
  useGetTableData,
  useDeleteTableData,
  useCreateTableData,
  usePutTableData,
} from "./hooks";
import "../../pages/main/main.module.css";
import Pen01 from "../icons/pen-01";
import "dayjs/locale/ko";
import CreateTableModal from "./CreateTableModal";
import EditTableModal from "./EditTableModal";
import { useSelectData } from "../../hooks/useSelectData";
import { MRT_Localization_KO } from "../../constants/localiztion";
import { COLORS } from "../../styles/colors";

function ReusableTable({
  columns,
  updateStatus,
  baseUrl = [],
  displayConfigObj,
  buttons = [],
  exlucdColumns = [],
  boxWidth = "100%",
  title = "",
  group_code,
  project_code,
  isRowEdit = false,
  deleteParam = "",
  selectFetchers = {},
  isTwoColumns,
  ...props
}) {
  const selectData = useSelectData(
    selectFetchers,
    updateStatus,
    group_code,
    project_code
  );
  // baseurl [0: get], [1: create], [2: update], [3: delete]
  const [rowSelection, setRowSelection] = useState([]);
  const [alignedColumns, setAlignedColumns] = useState([]);
  const [formData, setFormData] = useState({});
  const { data } = useGetTableData(
    updateStatus,
    baseUrl[0],
    group_code,
    project_code
  );
  useEffect(() => {
    const centerAlign = columns.map((item) => {
      return {
        ...item,
        muiTableHeadCellProps: {
          align: "center",
          // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
        },
        muiTableBodyCellProps: {
          align: "center",
          // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
        },
        muiTableFooterCellProps: {
          align: "center",
          // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
        },
      };
    });
    setAlignedColumns(centerAlign);
  }, [columns]);
  const tableData = data || [];
  const deleteDataMutation = useDeleteTableData(
    updateStatus,
    baseUrl[3],
    group_code,
    project_code
  );
  const createDataMutaion = useCreateTableData(
    updateStatus,
    baseUrl[1],
    group_code,
    project_code
  );
  const updateMutation = usePutTableData(
    updateStatus,
    baseUrl[2],
    group_code,
    project_code
  );
  const handleDeleteRow = (row) => {
    if (window.confirm("Are you sure you want to delete this row?")) {
      const { itemId, procId, machineId } = row.original;
    }
  };
  const handleButtonClick = (type, table) => {
    switch (type) {
      case "create":
        table.setCreatingRow(true);
        break;
      case "edit": {
        const selectedRows = table.getSelectedRowModel().rows;
        if (selectedRows.length > 0) {
          table.setEditingRow(selectedRows[0]);
        }
        break;
      }
      case "delete": {
        const handleDelete = async () => {
          const rowsToDelete = table.getSelectedRowModel().rows;
          console.log("deleteParam:", deleteParam);
          const deleted = rowsToDelete.map(
            (content) => content.original[deleteParam]
          );
          console.log("deleted:", deleted);
          if (
            rowsToDelete.length > 0 &&
            window.confirm("선택한 항목을 삭제하시겠습니까?")
          ) {
            try {
              await Promise.all(
                deleted.map((param) =>
                  deleteDataMutation.mutateAsync({
                    deleteParam: deleteParam,
                    param: param,
                  })
                )
              );
              setRowSelection([]);
            } catch (error) {
              console.error("삭제 프로세스 오류:", error);
            }
          }
        };
        handleDelete();
        break;
      }
      default:
        return null;
    }
  };
  const handleCreateProcess = async ({ values, table }) => {
    console.log("values : ", values);
    try {
      await createDataMutaion.mutateAsync(values);
      table.setCreatingRow(null);
      setRowSelection([]);
    } catch (error) {
      console.error("Error creating process:", error);
    }
  };
  const handleEditProcess = async ({ values, table, row }) => {
    const originalId = row.original[deleteParam];
    const updatedValues = {
      ...values,
      [deleteParam]: originalId,
    };
    try {
      await updateMutation.mutateAsync(updatedValues);
      table.setEditingRow(null);
      setRowSelection([]);
    } catch (error) {
      console.error("Error creating process:", error);
    }
  };
  return (
    <Box sx={{ width: boxWidth }}>
      <MaterialReactTable
        // enableRowOrdering
        columns={alignedColumns}
        data={tableData}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        state={{ rowSelection }}
        enableStickyHeader
        enableEditing={isRowEdit}
        createDisplayMode={displayConfigObj.createDisplayMode}
        editDisplayMode={displayConfigObj.editDisplayMode}
        enableTopToolbar
        enableSelectAll
        onCreatingRowSave={handleCreateProcess}
        onEditingRowSave={handleEditProcess}
        // onCreatingRowSave={({ values, table }) => {
        //   table.getState().creatingRow?.onSubmit(values);
        // }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () =>
            setRowSelection((prev) => ({
              ...prev,
              [row.id]: !prev[row.id],
            })),
          selected: rowSelection[row.id],
          sx: {
            cursor: "pointer",
            height: "50px",
            "& td": {
              backgroundColor: COLORS.BACKGROUND_BRIGHTER,
            },
            "&.Mui-selected": {
              height: "50px",
              backgroundColor: `${COLORS.BACKGROUND} !important`,
              "& td": {
                backgroundColor: COLORS.BACKGROUND,
              },
            },
            "&:hover": {
              height: "50px",
              backgroundColor: `${COLORS.BACKGROUND} !important`,
              "& td": {
                backgroundColor: COLORS.BACKGROUND,
              },
            },
          },
        })}
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            backgroundColor: COLORS.BACKGROUND_BRIGHTER,
            "& *": {
              fontFamily: "Pretendard !important",
            },
          },
        }}
        muiTopToolbarProps={{
          sx: {
            backgroundColor: COLORS.BACKGROUND_BRIGHTER,
            borderBottom: `1px solid ${COLORS.TABLE_BORDER}`,
            padding: "0.5rem 0",
            "& > div:nth-of-type(2)": {
              padding: "0 !important",
            },
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            backgroundColor: COLORS.BACKGROUND_BRIGHTER,
          },
        }}
        muiTableProps={{
          sx: {
            // // borderLeft: `1px solid ${COLORS.ACCENT}`,
            // borderRight: `1px solid ${COLORS.ACCENT}`,
            "& td, & th": {
              borderLeft: `1px solid ${COLORS.TABLE_BORDER}`,
            },
          },
        }}
        onRowSelectionChange={setRowSelection}
        props={props}
        localization={MRT_Localization_KO}
        enableColumnActions={false}
        renderCreateRowDialogContent={({ table }) => (
          <CreateTableModal
            table={table}
            handleCreateProcess={handleCreateProcess}
            exlucdColumns={exlucdColumns}
            selectData={selectData}
            isTwoColumns={isTwoColumns}
          />
        )}
        renderEditRowDialogContent={({ table, row }) => (
          <EditTableModal
            exlucdColumns={exlucdColumns}
            table={table}
            row={row}
            selectData={selectData}
            isTwoColumns={isTwoColumns}
          />
        )}
        // renderEditRowDialogContent={({ table, row }) => (
        //   <EditTableModal
        //     exlucdColumns={exlucdColumns}
        //     table={table}
        //     row={row}
        //   />
        // )}
        // renderEditRowDialogContent={({
        //   table,
        //   row,
        //   internalEditComponents,
        // }) => (
        //   <>
        //     <DialogTitle variant="h3">Edit User</DialogTitle>
        //     <DialogContent
        //       sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        //     >
        //       {internalEditComponents}{" "}
        //       {/* or render custom edit components here */}
        //     </DialogContent>
        //     <DialogActions>
        //       <MrtButtons variant="text" table={table} row={row} />
        //     </DialogActions>
        //   </>
        // )}
        // renderEditRowDialogContent={({
        //   row,
        //   table,
        //   internalEditComponents,
        // }) => {
        //   console.log("internalEditComponents : ", internalEditComponents);
        //   const filteredComponents = internalEditComponents.filter(
        //     ({ key }) => !exlucdColumns.includes(key.slice(2))
        //   );
        //   return (
        //     <>
        //       <DialogTitle variant="h5">Create New User</DialogTitle>
        //       <DialogContent
        //         sx={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "1rem",
        //         }}
        //       >
        //         {filteredComponents}
        //       </DialogContent>
        //       <DialogActions>
        //         <MrtButtons variant="text" table={table} row={row} />
        //       </DialogActions>
        //     </>
        //   );
        // }}
        positionActionsColumn="last"
        renderRowActions={({ row, table }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => table.setEditingRow(row)}
              style={{ cursor: "pointer" }}
            >
              <Tooltip title="Edit">
                <Pen01 />
              </Tooltip>
            </div>
          </Box>
        )}
        renderTopToolbarCustomActions={() => {
          return (
            <Box
              sx={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                fontSize: "1.268rem",
                background: "transparent",
                fontWeight: "600",
                gap: "1rem",
                padding: "0",
                color: COLORS.PRIMARY,
              }}
            >
              {title}
            </Box>
          );
        }}
        positionToolbarAlertBanner="bottom"
        muiTableHeadRowProps={{
          sx: {
            backgroundColor: COLORS.PRIMARY,
            "& .MuiTableCell-head": {
              color: COLORS.TEXT_BRIGHT,
              fontWeight: "600",
            },
          },
        }}
        muiToolbarAlertBannerProps={{
          sx: {
            bgcolor: COLORS.BACKGROUND_BRIGHTER,
            color: COLORS.TEXT_NORMAL,
            "& .MuiAlert-message": {
              color: COLORS.TEXT_NORMAL,
              fontFamily: "Pretendard",
              fontSize: "0.875rem",
            },
            "& .MuiAlert-icon": {
              color: COLORS.TEXT_NORMAL,
            },
            "& .MuiAlert-action": {
              color: COLORS.TEXT_NORMAL,
            },
            padding: "8px 16px",
            margin: 0,
            borderTop: `1px solid ${COLORS.ACCENT}`,
          },
        }}
        renderToolbarInternalActions={({ table }) => (
          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            {buttons.map((button, index) => (
              <Button
                key={index}
                variant={button.variant}
                disabled={
                  button.type === "delete" &&
                  table.getSelectedRowModel().rows.length === 0
                }
                onClick={() => handleButtonClick(button.type, table)}
              >
                {button.text || ""}
              </Button>
            ))}
          </Box>
        )}
        // enableRowDragging
        // muiRowDragHandleProps={({ row, table }) => ({
        //   sx: {
        //     width: "30px",
        //     height: "30px",
        //     textAlign: "center",
        //     position: "absolute",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     "& svg": {
        //       display: "none",
        //     },
        //     "::before": {
        //       // content: () => {
        //       //   if (tableData.length === 1) {
        //       //     return "url(./cursor-not-allowed.svg)";
        //       //   }
        //       //   if (tableData[0].proc_id === row.original.proc_id) {
        //       //     return "url(./arrow-down-from-line.svg)";
        //       //   }
        //       //   if (
        //       //     tableData[tableData.length - 1].proc_id === row.original.proc_id
        //       //   ) {
        //       //     return "url(./arrow-up-from-line.svg)";
        //       //   }
        //       //   return "url('./arrows-from-line-y.svg')";
        //       // },
        //       width: "30px",
        //       height: "30px",
        //     },
        //   },
        //   onDragEnd: async () => {
        //     const { draggingRow, hoveredRow } = table.getState();
        //     console.log(draggingRow, hoveredRow);
        //     // if (hoveredRow && draggingRow) {
        //     //   const newData = [...tableData];
        //     //   newData.splice(
        //     //     hoveredRow.index,
        //     //     0,
        //     //     newData.splice(draggingRow.index, 1)[0]
        //     //   );
        //     //   const arr = newData.map((c, i) => {
        //     //     return {
        //     //       item_id: c.item_id,
        //     //       proc_id: c.proc_id,
        //     //       proc_sequence_no: i + 1,
        //     //     };
        //     //   });
        //     //   await updateSequence.mutateAsync(arr);
        //     // }
        //   },
        // })}
      />
    </Box>
  );
}

export default ReusableTable;
