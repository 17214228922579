import { useEffect, useState } from "react";
import { useProjectApi } from "./useProjectApi";

export const useCalculationData = (group_code, project_code) => {
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const api = useProjectApi(group_code, project_code);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const frequencyRes = await api.get("/getSafCalFrequency");
        const importancyRes = await api.get("/getSafCalImportancy");
        const standardRes = await api.get("/getSafCalStandard");
        const mergedData = {
          frequencyData: frequencyRes.data,
          importancyData: importancyRes.data,
          standardData: standardRes.data,
        };
        setTableData(mergedData);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return {
    tableData,
    loading,
  };
};
