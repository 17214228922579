import Header from "../../components/Layout/Header";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useProjectApi } from "../../hooks/useProjectApi";
import RedCheck from "../../components/icons/RedCheck";
import styles from "./main.module.css";

function RiskAssessmentMain() {
  const { group_code, project_code } = useParams();
  const api = useProjectApi(group_code, project_code);
  useEffect(() => {
    const initializeProject = async () => {
      try {
        const res = await api.get("/getAssetMasterInformation");
        console.log("res : ", res);
      } catch (error) {
        console.error("프로젝트 초기화 실패:", error);
      }
    };
    initializeProject();
  }, []);

  return (
    <>
      <Header group_code={group_code} project_code={project_code} />
      <section className={styles.sectionBanner}>
        <div className={styles.sectionBannerLeft}>
          <h1 className={styles.sectionBannerLeftH1}>
            Industrial Process Risk Assessment System With <br />
            <div className={styles.sectionBannerLeftH1Name}>InsighTwin</div>
          </h1>
          <p className={styles.sectionBannerLeftP}>
            <RedCheck />
            &nbsp;&nbsp;&nbsp; Enhanced accuracy and efficiency in risk
            assessment
            <br />
            <RedCheck />
            &nbsp;&nbsp;&nbsp; Comprehensive data management and historical
            analysis
            <br />
            <RedCheck />
            &nbsp;&nbsp;&nbsp; Real-time monitoring and updates
            <br />
            <RedCheck />
            &nbsp;&nbsp;&nbsp; Automated regulatory compliance
          </p>
        </div>
        <div className={styles.sectionBannerRight}>
          <img
            className={styles.sectionBannerRightImg}
            src="/images/main_safe.jpg"
            alt="main_section_img"
          />
        </div>
      </section>
    </>
  );
}

export default RiskAssessmentMain;
