import React, { useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table as BTable } from "react-bootstrap";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import API_URL from "../../../utils/API_URL";
import axios from "axios";
import { useSelector } from "react-redux";
import { ThElement } from "../../M/styles";
import { lineCoulmns } from "../../../utils/columns";
import styled from "styled-components";

const StyledTable = styled(BTable)`
  .t_thead {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
    border: none !important;
  }
  border: none !important;
  .t_tr .t_th:first-child {
    border-left: 1px solid #d3d3d3 !important;
  }
`;

function SummaryLine() {
  const dataQuery = useQuery({
    queryKey: ["data"],
    queryFn: async () => {
      const res = await axios.get(API_URL + "/data/getPlanSummaryLine");
      return res.data;
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo(() => {
    return [
      ...lineCoulmns.slice(0, -1),
      {
        ...lineCoulmns[lineCoulmns.length - 1],
        cell: ({ row }) => {
          const planQty = parseFloat(row.original.item_qty) || 0;
          const resultQty = parseFloat(row.original.result_qty) || 0;
          const rate = planQty > 0 ? (resultQty / planQty) * 100 : 0;
          const formattedRate = rate.toFixed(2) + "%";
          const color = rate >= 100 ? "red" : "blue";
          return <span style={{ color }}>{formattedRate}</span>;
        },
      },
    ];
  }, []);

  const table = useReactTable({
    data: dataQuery.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        { id: "line_name", desc: false },
        { id: "mch_name", desc: false },
      ],
    },
    debugTable: true,
    columnResizeMode: "onChange",
  });

  const summaryLineStatus = useSelector(
    (state) => state.auth.summaryLineStatus
  );

  // 병합된 셀 정보를 계산
  const mergedCells = useMemo(() => {
    if (!dataQuery.data) return {};

    const mergedInfo = {};
    table.getRowModel().rows.forEach((row, index) => {
      const lineName = row.getValue("line_name");
      const mchName = row.getValue("mch_name");
      const key = `${lineName}-${mchName}`;

      if (!mergedInfo[key]) {
        mergedInfo[key] = { startIndex: index, rowSpan: 1, type: "both" };
      } else {
        mergedInfo[key].rowSpan += 1;
      }

      if (!mergedInfo[lineName]) {
        mergedInfo[lineName] = { startIndex: index, rowSpan: 1, type: "line" };
      } else {
        mergedInfo[lineName].rowSpan += 1;
      }
    });
    return mergedInfo;
  }, [table, dataQuery.data]);

  if (dataQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (dataQuery.isError) {
    return <div>Error: {dataQuery.error.message}</div>;
  }

  return (
    <>
      <StyledTable
        className="t_table"
        striped
        bordered
        hover
        responsive
        size="sm"
      >
        <thead className="t_thead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="t_tr" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <ThElement
                  className="t_th center"
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                    minWidth: header.getSize(),
                    border: "none !important",
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </ThElement>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="t_tbody">
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr className="t_tr" key={row.id}>
              {row.getVisibleCells().map((cell) => {
                if (
                  cell.column.id === "line_name" ||
                  cell.column.id === "mch_name"
                ) {
                  const value = cell.getValue();
                  const key =
                    cell.column.id === "line_name"
                      ? value
                      : `${row.getValue("line_name")}-${value}`;
                  const mergedInfo = mergedCells[key];
                  if (mergedInfo && mergedInfo.startIndex === rowIndex) {
                    return (
                      <td
                        className="t_td"
                        key={cell.id}
                        rowSpan={mergedInfo.rowSpan}
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                          width: cell.column.getSize(),
                          minWidth: cell.column.getSize(),
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  }
                  return null;
                }
                return (
                  <td
                    style={{
                      width: cell.column.getSize(),
                      minWidth: cell.column.getSize(),
                    }}
                    className="t_td"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
}

export default SummaryLine;
