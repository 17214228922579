import { useEffect, useState } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";

export const dataManipulating = (data) => {
  const originalDate = new Date(data);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0");
  const day = String(originalDate.getDate()).padStart(2, "0");
  const hours = String(originalDate.getHours()).padStart(2, "0");
  const minutes = String(originalDate.getMinutes()).padStart(2, "0");
  return year + "-" + month + "-" + day + "T" + hours + ":" + minutes;
};

export const transformDateForGantt = (date) => {
  const dateParts = date.split("T")[0].split("-");
  const day = parseInt(dateParts[2]);
  const month = parseInt(dateParts[1]);
  const year = parseInt(dateParts[0]);
  const formattedDate = day + "-" + month + "-" + year;
  const inputTime = date.split("T")[1];
  const formattedTime = inputTime;
  return formattedDate + " " + formattedTime;
};

function sortByEngNameFirst(arr) {
  return arr.sort((a, b) => {
    const engRegex = /^[A-Za-z]/;
    if (
      (engRegex.test(a) && engRegex.test(b)) ||
      (!engRegex.test(a) && !engRegex.test(b))
    ) {
      return a.localeCompare(b, "ko-KR");
    }
    if (engRegex.test(a)) {
      return -1;
    }
    if (engRegex.test(b)) {
      return 1;
    }
  });
}

export const useGanttSimulation1 = () => {
  const [simGData, setSimGData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(API_URL + "/data/getGanttSimulation1");
        const resData = res.data.map((item) => ({
          ...item,
          start_date: item.start_date.replace("T", " ").replace(".000Z", " "),
          end_date: item.end_date.replace("T", " ").replace(".000Z", ""),
        }));
        let ganttId = 1;
        let arr = [];
        const mchNameSet = sortByEngNameFirst([
          ...new Set(resData.map((c) => c.mch_name)),
        ]);
        for (let i = 0; i < mchNameSet.length; i++) {
          const matchedData = resData.filter(
            (c) => c.mch_name === mchNameSet[i]
          );
          const parentDataObj = {
            id: ganttId,
            type: "project",
            start_date: new Date(2024, 9, 22, 0),
            end_date: new Date(2024, 9, 22, 24),
            render: "split",
            open: false,
            text: matchedData[0]?.mch_name,
          };
          arr.push(parentDataObj);
          const parentId = ganttId;
          ganttId++;
          for (let ii = 0; ii < matchedData.length; ii++) {
            const dataObj = {
              ...matchedData[ii],
              id: ganttId,
              parent: parentId,
              text: matchedData[ii].item_name,
              start_date: transformDateForGantt(
                dataManipulating(matchedData[ii].start_date)
              ),
              end_date: transformDateForGantt(
                dataManipulating(matchedData[ii].end_date)
              ),
              type: "task",
              color: matchedData[ii].color,
            };
            arr.push(dataObj);
            ganttId++;
          }
        }
        setSimGData(arr);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);
  return {
    simGData,
  };
};
