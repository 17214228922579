import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppBar, Avatar, Toolbar, Typography, Box, IconButton, useMediaQuery, useTheme, Drawer, Divider, Menu, MenuItem, styled } from '@mui/material';
import { useAuth } from "../hooks/useAuth";
import { Icon } from '../components/icons';
import GroupSidebar from '../components/SideBar/GroupAppSidebar';

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  '&:hover .MuiBox-root, &:hover .MuiBox-root svg': {
    color: theme.palette.primary.main
  }
}))

const MenuStyles = {
  px: 4,
  py: 1.75,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'text.primary',
  textDecoration: 'none',
  '& svg': {
    mr: 2.5,
    fontSize: '1.5rem',
    color: 'text.secondary'
  }
}

const ProjectAppLayout = () => {
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const auth = useAuth();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [anchorEl, setAnchorEl] = useState(null)

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    auth.logout();
  };

  return (
    <Box sx={{ height: "100vh", display: "flex" }}>
      <GroupSidebar />
      <Box
        sx={{
          width: `calc(100vw - 80px)`,
          display: "flex",
          flexDirection: "column",
          transition: "width 0.3s",
        }}
      >
        <AppBar
          color="default"
          elevation={2}
          position="sticky"
          sx={{
            alignItems: "center",
            color: "text.primary",
            justifyContent: "center",
            transition: "border-bottom 0.2s ease-in-out",
            backgroundColor: "rgba(255, 255, 255, 0.88)",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Toolbar>
              <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h4" sx={{ ml: 2.5, fontWeight: 500, lineHeight: "24px", opacity: 0.8 }}>
                  InsighTwin
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }} onClick={handleDropdownOpen}>
                  {!isMdUp ? (
                    <Box sx={{ height: "41px", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={null}>
                      <Avatar sx={{ width: 38, height: 38 }}>
                        <Icon name="userIcon" />
                      </Avatar>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center", pr: "20px", pl: "20px", cursor: "pointer" }} onClick={null}>
                      <Avatar sx={{ width: 38, height: 38, mr: 4 }}>
                        <Icon name="userIcon" />
                      </Avatar>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6">{user.user_name}</Typography>
                        <Typography variant="body2" sx={{ color: "text.disabled" }}>
                          {user.group_name}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleDropdownClose()}
                  sx={{ '& .MuiMenu-paper': { width: 230, mt: "12px" } }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Box sx={{ py: 1.75, px: 6 }}>
                    <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                      <Avatar sx={{ width: 38, height: 38, mr: 4 }}>
                        <Icon name="userIcon" />
                      </Avatar>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6">{user.user_name}</Typography>
                        <Typography variant="body2" sx={{ color: "text.disabled" }}>
                          {user.group_name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ my: theme => `${theme.spacing(2)} !important` }} />
                  <MenuItemStyled sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
                    <Box sx={MenuStyles}>
                      <Icon 
                        name="userEditIcon"
                        style={{ 
                          width: '22px', 
                          height: '22px',
                        }}
                      />
                      프로필 설정
                    </Box>
                  </MenuItemStyled>
                  <Divider sx={{ my: theme => `${theme.spacing(2)} !important` }} />
                  <MenuItemStyled sx={{ p: 0 }} onClick={handleLogout}>
                    <Box sx={MenuStyles}>
                      <Icon 
                        name="logoutIcon"
                        style={{ 
                          width: '22px', 
                          height: '22px',
                        }}
                      />
                      로그아웃
                    </Box>
                  </MenuItemStyled>
                </Menu>
              </Box>
            </Toolbar>
          </Box>
        </AppBar>
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectAppLayout;