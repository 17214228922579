import axios from "axios";
import { setupAxiosInterceptors } from "./setupInterceptors";
import API_URL from "../utils/API_URL";

// const BASE_URL = "https://dev.insightwin.com";

const BASE_URL = API_URL

// 일반 Axios 인스턴스
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// 보호된 API Axios 인스턴스
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// 인터셉터 초기화
setupAxiosInterceptors(axiosPrivate);