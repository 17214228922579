import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';

// ** MUI Components
import { Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoginWrapper from '../Login/LoginWrapper';

// ** Styled Components
const ContentBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.common.white,
  padding: theme.spacing(4),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[4],
}));

function LandingPage() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
      if (user) {
        navigate(isMobile ? "/mobile" : "/projects", { replace: true });
      }
    }, [user, navigate]);

  return (
    <LoginWrapper>
      <ContentBox>
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          디지털트윈 플랫폼 InsighTwin 로그인 URL을 확인해주세요.
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          도움이 필요하신가요?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="https://s-prodis.com/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ px: 4, py: 1.5, fontSize: '1.2rem' }}
        >
          (주)에쎄테크놀로지 홈페이지로 방문해주세요.
        </Button>
      </ContentBox>
    </LoginWrapper>
  );
}

export default LandingPage;
