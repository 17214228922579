import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Icon } from '../icons'
import CustomAvatar from "../mui/avartar";

const ConfirmDialog = ({ open, onConfirm, onCancel, actionType, extraSentence, iconName, iconColor, iconSize }) => {
  const handleConfirmation = () => {
    onConfirm();
  }

  return (
    <Dialog fullWidth open={open} onClose={onCancel} sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: 512 } }}>
      <DialogContent
        sx={{
          px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
          pt: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
        }}
      >
        <Box
          sx={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <CustomAvatar skin="light" color="error" sx={{ mb: 5, width: 100, height: 100 }}>
            <Icon name={iconName} color={iconColor} width={iconSize} height={iconSize} />
          </CustomAvatar>
          <Typography variant='h5' sx={{ mb: 5, color: 'text.secondary' }}>
            {actionType}하시겠습니까?
          </Typography>
          <Typography>{extraSentence}</Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
          pb: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
        }}
      >
        <Button variant='contained' sx={{ mr: 2 }} onClick={handleConfirmation}>
          삭제
        </Button>
        <Button variant='tonal' color='secondary' onClick={onCancel}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;