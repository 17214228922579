import { createContext, useRef, useEffect, useCallback, useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { isMobile } from 'react-device-detect'; // 모바일 디바이스 감지
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser, logout } from "../slice/authSlice";
import authConfig from "../auth/auth";
import { getToken, setToken, clearToken } from "../auth/tokenStore";
import useRefreshToken from "../hooks/useRefreshToken";

const AuthContext = createContext({
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  deviceId: null,
  errorMessage: "",
  setErrorMessage: () => {},
});

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const refresh = useRefreshToken();

  const [isLoading, setIsLoading] = useState(true);
  const [deviceId, setDeviceId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const initializeRef = useRef(false);

  // Device ID 초기화
  const initializeDeviceId = useCallback(() => {
    const deviceIdKey = "device_id";
    let id = localStorage.getItem(deviceIdKey);
    if (!id) {
      id = uuidv4();
      localStorage.setItem(deviceIdKey, id);
      console.log("새로운 Device ID 생성:", id);
    } else {
      console.log("기존 Device ID 사용:", id);
    }
    setDeviceId(id); // 상태 업데이트
    return id; // Device ID 반환
  }, []);

  // Auth 초기화
  const initializeAuth = useCallback(async () => {
    if (initializeRef.current) return; // 이미 실행된 경우 종료
    initializeRef.current = true;

    const id = initializeDeviceId(); // Device ID 생성

    const currentToken = getToken();

    // HttpOnly 쿠키에 저장된 Refresh Token는 접근 불가함. 따라서 access token 존재 여부만으로 항상 서버에 체크하는 방법 밖엔 없음.
    if (!currentToken) {
      try {
        await refresh(id); // Device ID를 전달
      } catch (error) {
        setErrorMessage("세션이 만료되었습니다. 다시 로그인 해주세요.");
        clearToken();
        dispatch(logout());
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [initializeDeviceId, refresh, dispatch]);

  // 앱 초기화
  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  useEffect(() => {
    console.log("유저확인:", user);
  }, [user]);

  const handleLogin = useCallback(
    async (params) => {
      try {
        const response = await axios.post(authConfig.loginEndpoint, params, {
          withCredentials: true,
        });
        setToken(response.data.accessToken);
        dispatch(setUser(response.data.user));
        const currentGroupCode = response.data.user?.group_code || "";
        const groupLoginPagePath = currentGroupCode ? `/login/${currentGroupCode}` : "/";

        // 로그인 페이지 경로를 쿠키 저장
        document.cookie = `groupLoginPagePath=${groupLoginPagePath}; path=/; max-age=31536000`;

        // 로그인 후 모바일과 데스크탑에 따라 다른 경로로 리다이렉트
        if (isMobile) {
          navigate("/mobile"); // 모바일이면 /mobile로 이동
        } else {
          navigate("/projects");
        }

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setErrorMessage("로그인 실패: 아이디 또는 비밀번호를 확인해주세요.");
        } else {
          setErrorMessage("로그인 중 문제가 발생했습니다.");
        }
      }
    },
    [navigate, dispatch]
  );

  const handleLogout = useCallback(async () => {
    try {      
      await axios.post(authConfig.logoutEndpoint, {}, { withCredentials: true });
      clearToken();
      dispatch(logout());
      
      // 쿠키에서 그룹 로그인 페이지 확인
      const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
        const [key, value] = cookie.split("=");
        acc[key] = value;
        return acc;
      }, {});
      const groupLoginPagePath = cookies.groupLoginPagePath || "/";

      navigate(groupLoginPagePath);

    } catch (error) {
      console.error("로그아웃 중 오류:", error);
      setErrorMessage("로그아웃에 문제가 발생했습니다.");
    }
  }, [navigate, dispatch]);

  const values = useMemo(
    () => ({
      login: handleLogin,
      logout: handleLogout,
      deviceId,
      errorMessage,
      setErrorMessage,
    }),
    [handleLogin, handleLogout, deviceId, errorMessage]
  );

  return (
    <AuthContext.Provider value={values}>
      {isLoading ? <p>Loading...</p> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };