export const standardRegisterColumns = [
  {
    accessorKey: "process_id",
    header: "No.",
    size: 50,
    maxSize: 70,
    minSize: 50,
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "process_name",
    header: "공정명",
    size: 150,
    type: "text",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "process_description",
    header: "공정 내용",
    size: 400,
    type: "text",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
];

export const standardLawColumns = [
  {
    accessorKey: "law_name",
    header: "법규명",
    type: "text",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "law_active_date",
    header: "시행 일자",
    size: 140,
    type: "date",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "law_url",
    header: "관련 사이트",
    Cell: ({ row }) => (
      <a
        href={row.original.law_url}
        target="_blank"
        rel="noopener noreferrer"
        className="url_link"
      >
        {row.original.law_url}
      </a>
    ),
    type: "text",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "law_information",
    header: "법규 정보",
    type: "text",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
];

export const standardGoalColumns = [
  {
    accessorKey: "goal_id",
    header: "No.",
    size: 50,
    maxSize: 70,
    minSize: 50,
    muiTableHeadCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "goal_description",
    header: "목표",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
];

export const standardCourseColumns = [
  {
    accessorKey: "course_id",
    header: "No.",
    size: 50,
    maxSize: 70,
    minSize: 50,
    muiTableHeadCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "course_description",
    header: "방침",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
];

export const standardOrgColumns = [
  {
    accessorKey: "org_id",
    header: "No.",
    size: 50,
    maxSize: 70,
    minSize: 50,
    muiTableHeadCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "role_name",
    header: "담당",
    size: 80,
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "position_name",
    header: "직위",
    Cell: ({ row }) => {
      const positionName = row.original.position_name;
      if (positionName !== null) return positionName;
      const note = row.original.note;
      if (!note) return null;
      const splitNote = note.split("/");
      return splitNote[0] || null;
    },
    size: 80,
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "emp_name",
    header: "성명",
    Cell: ({ row }) => {
      const empName = row.original.emp_name;
      if (empName !== null) return empName;
      const note = row.original.note;
      if (!note) return null;
      const splitNote = note.split("/");
      return splitNote[1] || null;
    },
    size: 80,
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
];

export const standardCauseColumns = [
  {
    accessorKey: "danger_category",
    header: "위험분류",
    size: 120,
    type: "text",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "danger_cause",
    header: "위험원인",
    size: 200,
    type: "text",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  {
    accessorKey: "danger_description",
    header: "위험요인 발생상황 및 결과",
    size: 550,
    type: "text",
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
];

export const standardMchInfoColumns = [
  {
    accessorKey: "asset_manage_code",
    header: "설비관리번호",
    type: "text",
    size: 130,
    muiTableHeadCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableBodyCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
    muiTableFooterCellProps: {
      align: "center",
      // sx: { width: "50px", minWidth: "50px", maxWidth: "70px !important" },
    },
  },
  { accessorKey: "asset_name", header: "설비명", type: "text", size: 130 },

  {
    accessorKey: "asset_category_name",
    header: "설비분류",
    type: "select",
    selectKey: "assetCategoryList",
    valueKey: "asset_category_id",
    labelKey: "asset_category_name",
    size: 50,
  },
  {
    accessorKey: "asset_group_name",
    header: "설비그룹",
    type: "select",
    selectKey: "assetGroupList",
    valueKey: "asset_group_id",
    labelKey: "asset_group_name",
    size: 50,
  },
  {
    accessorKey: "asset_manufacturer",
    header: "제작사",
    type: "select",
    size: 50,
  },
  { accessorKey: "asset_model_name", header: "모델명", type: "text", size: 50 },
  { accessorKey: "asset_standard", header: "규격", type: "text", size: 100 },
  {
    accessorKey: "asset_purchase_date",
    header: "구입일자",
    type: "date",
    size: 120,
  },
  { accessorKey: "asset_age", header: "내용연수", type: "text", size: 50 },
  {
    accessorKey: "asset_size",
    header: "설비 SIZE(mm)",
    type: "text",
    size: 200,
  },
  { accessorKey: "asset_voltage", header: "전압", type: "text", size: 100 },
];

export const standardScenarioColumns = [
  {
    accessorKey: "sit_response",
    header: "상황대응",
    size: 250,
  },
  { accessorKey: "supervisor", header: "책임자", size: 125 },
  { accessorKey: "coop_department", header: "협조 부서", size: 90 },
  { accessorKey: "note", header: "비고", size: 50 },
];
