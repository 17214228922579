// 중대성(강도) 테이블
function ImportancyTable() {
  return (
    <div>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th
              colSpan={2}
              className="bg-blue-100 border border-gray-300 py-2 text-center font-bold"
            >
              중대성(강도)
            </th>
          </tr>
        </thead>
        <tbody className="font-bold">
          <tr>
            <td className="text-center border border-gray-300 px-4 py-2 w-24">
              최대(4)
            </td>
            <td className="text-center border border-gray-300 px-4 py-2">
              사망 또는 영구장애
            </td>
          </tr>
          <tr>
            <td className="text-center border border-gray-300 px-4 py-2">
              대(3)
            </td>
            <td className="text-center border border-gray-300 px-4 py-2">
              장기치료 필요한 부상
            </td>
          </tr>
          <tr>
            <td className="text-center border border-gray-300 px-4 py-2">
              중(2)
            </td>
            <td className="text-center border border-gray-300 px-4 py-2">
              단기치료 필요한 부상
            </td>
          </tr>
          <tr>
            <td className="text-center border border-gray-300 px-4 py-2">
              소(1)
            </td>
            <td className="text-center border border-gray-300 px-4 py-2">
              경미 부상 또는 치료 無
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ImportancyTable;
