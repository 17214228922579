import React, { useState } from "react";
import { useGetTitle } from "../../../hooks/useGetTitle";
import { useParams } from "react-router-dom";
import Header from "../../../components/Layout/Header";
import TableComponent from "../../../components/common/SingleTableComponent";
import ReusableTable from "../../../components/Table/ReusableTable";
import styles from "../../main/main.module.css";
import { standardScenarioColumns } from "../../../constants/columns";

function StandardScenario() {
  const { group_code, project_code } = useParams();
  const titleInfo = useGetTitle();
  const { title, subTitle } = titleInfo;
  const displayModeObject = {
    createDisplayMode: "modal",
    editDisplayMode: "modal",
  };

  return (
    <>
      <Header group_code={group_code} project_code={project_code} />
      <TableComponent title={title} subTitle={subTitle} $display="flex">
        <ReusableTable
          displayConfigObj={displayModeObject}
          columns={standardScenarioColumns}
          group_code={group_code}
          project_code={project_code}
          title="화재 시나리오"
          baseUrl={["/getSafeScenario?sitCategory=1"]}
          boxWidth="30vw"
        />
        <ReusableTable
          columns={standardScenarioColumns}
          displayConfigObj={displayModeObject}
          group_code={group_code}
          project_code={project_code}
          title="천재지변 시나리오"
          baseUrl={["/getSafeScenario?sitCategory=2"]}
          boxWidth="30vw"
        />
        <ReusableTable
          columns={standardScenarioColumns}
          displayConfigObj={displayModeObject}
          group_code={group_code}
          project_code={project_code}
          title="전기정전 시나리오"
          baseUrl={["/getSafeScenario?sitCategory=3"]}
          boxWidth="30vw"
        />
      </TableComponent>
    </>
  );
}

export default StandardScenario;
