import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, IconButton, useMediaQuery, useTheme, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import EditSidebar from '../components/SideBar/EditSidebar';

const ProjectEditLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [groupName, setGroupName] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const theme = useTheme();
  const location = useLocation();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleMenuClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setGroupName(queryParams.get("groupName"));
    setProjectName(queryParams.get("projectName"));
  }, [location.search]);

  return (
    <Box sx={{ height: '100vh', display: 'flex' }}>
      {isMdUp ? (
        <EditSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      ) : (
        <Drawer
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          variant="temporary"
          ModalProps={{
            keepMounted: true, 
          }}
          PaperProps={{
            sx: { 
              width: collapsed ? 80 : 190,
              height: '100vh'
            } 
          }}
        >
          <EditSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        </Drawer>
      )}

      <Box sx={{ 
        width: isMdUp ? `calc(100vw - ${collapsed ? 80 : 190}px)` : '100%', 
        display: 'flex', 
        flexDirection: 'column',
        transition: 'width 0.3s'
      }}>
        <AppBar position="static">
          <Toolbar>
            {!isMdUp && (
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h4" component="div" sx={{ flexGrow: 1, textAlign: !isMdUp ? 'center' : 'left', color: 'white', mr: !isMdUp ? '38.5px' : '0' }}>
              {projectName} 프로젝트 관리 페이지 - {groupName}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <Outlet context={{ groupName, projectName }} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectEditLayout;