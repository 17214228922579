import React, { useState, useEffect } from 'react';
import {useLocation, useParams } from "react-router-dom";
import { axiosPrivate } from "../../../api/axios";
import { DataGrid } from '@mui/x-data-grid';
import CustomPagination from '../../pagination/CustomPagination';
import * as XLSX from 'xlsx-js-style';
import { Box, Card, Button, CardHeader, CardContent, Grid } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import DatagridButtonGroup from '../ButtonGroup/DatagridButtonGroup';

const MachineStatusPage = () => {
  const location = useLocation();
  const [projectId, setProjectId] = useState(null);
  const { group_code, project_code } = useParams();
  const [machineStatus, setMachineStatus] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [newRowId, setNewRowId] = useState(1);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pid = queryParams.get("pid");
    if (pid) {
      setProjectId(pid);
    }
  }, [location.search]);
  
  const fetchMachineStatus = React.useCallback(async () => {
    if (!projectId) return;  // projectId가 없을 경우 실행 방지
    try {
      const res = await axiosPrivate.post('/table/machinestatus', {
        project_id: projectId,
        group_code,
        project_code,
      });
  
      const fetchedColumns = res.data.columns.map((col) => ({
        field: col.COLUMN_NAME,
        headerName: col.COLUMN_NAME,
        editable: col.COLUMN_NAME !== 'updateDate' ? true : false,
        type: col.DATA_TYPE === 'int' || col.COLUMN_NAME === 'mchId' ? 'number' : 'string',
        width: col.COLUMN_NAME === 'monitorTitle' || col.COLUMN_NAME === 'monitorText'|| col.COLUMN_NAME === 'procStartTime'|| col.COLUMN_NAME === 'updateDate' ? 200 : 100,
        headerAlign: 'center',
        align: col.COLUMN_NAME === 'monitorText' ? 'left' : 'center',
      }));
  
      const dataWithId = res.data.data.map((row, index) => ({
        ...row,
        id: row.id || index + 1,
      }));
  
      setColumns(fetchedColumns);
      setMachineStatus(dataWithId);
    } catch (err) {
      console.error("데이터 조회 실패:", err);
    }
  }, [projectId, group_code, project_code]);
  
  useEffect(() => {
    if (projectId) {
      fetchMachineStatus();
    }
  }, [projectId, fetchMachineStatus]);

  const handleAddRow = () => {
    const newRow = {
      id: `new-${newRowId}`,  // 임시 ID
    };
    columns.forEach(col => {
      newRow[col.field] = '';  // 새 행 초기화
    });
    setMachineStatus((prev) => [newRow, ...prev]);
    setNewRowId((prev) => prev + 1);
  };

  const handleDeleteRow = async () => {
    if (selectionModel.length === 0) {
      alert("삭제할 행을 선택해주세요.");
      return;
    }

    try {
      await axiosPrivate.delete('/table/machinestatus/delete', {
        data: { ids: selectionModel }
      });
      setMachineStatus((prev) => prev.filter((row) => !selectionModel.includes(row.id)));
      setSelectionModel([]);
      alert("삭제 완료!");
    } catch (err) {
      console.error("삭제 실패:", err);
      alert("데이터 삭제 중 오류가 발생했습니다.");
    }
  };

  const handleSaveRow = async () => {
    const modifiedRows = machineStatus.filter(row => row.editable);
    try {
      await axiosPrivate.post('/table/machinestatus/save', {
        rows: modifiedRows,
        group_code,
        project_code,
      });
      fetchMachineStatus();
      alert("변경사항이 저장되었습니다.");
    } catch (err) {
      console.error("저장 실패:", err);
      alert("저장 중 오류가 발생했습니다.");
    }
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(machineStatus);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "MachineStatus");
    XLSX.writeFile(wb, `MachineStatus_${project_code}.xlsx`);
  };

  return (
    <Grid container spacing={0} sx={{ height: "100%", p: 4 }}>
      <Grid item xs={12} sx={{ height: "100%" }}>
        <Card sx={{ height: "100%", display: 'flex', flexDirection: 'column' }}>
          <CardHeader
            title="Machine Status 테이블"
            action={
              <Box display="flex" gap={1}>
                
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FileDownloadIcon />}
                  onClick={handleExportExcel}
                >
                  Excel 다운로드
                </Button>
              </Box>
            }
          />
          
          <DatagridButtonGroup
            handleAddRow={handleAddRow}
            handleDeleteRow={handleDeleteRow}
            handleSaveRow={handleSaveRow}
            selectionModel={selectionModel}
          />

          <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            <DataGrid
              rows={machineStatus}
              columns={columns}
              pageSize={paginationModel.pageSize}
              rowsPerPageOptions={[20, 50, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection) => {
                setSelectionModel(newSelection);
              }}
              disableSelectionOnClick
              slots={{
                pagination: CustomPagination,
              }}
              autosizeOptions={{
                columns: columns.map(col => col.field),
                includeOutliers: false,
                includeHeaders: true
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MachineStatusPage;