import React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  LocalizationProvider,
  DatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import { COLORS } from "../../styles/colors";

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  boxShadow: 24,
  padding: theme.spacing(4),
}));

const commonSx = {
  marginBottom: 3,
  borderRadius: "4px",
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
  },
  "& .MuiInputLabel-root": {
    marginTop: "0px",
  },
  "& .MuiInputLabel-shrink": {
    marginTop: "0px",
  },
};

function CreateTableModal({
  table,
  handleCreateProcess,
  exlucdColumns,
  selectData,
  isTwoColumns = false,
}) {
  const formatDateValue = (value, type) => {
    if (!value) return null;
    const dayjsValue = dayjs(value);
    if (!dayjsValue.isValid()) return null;
    if (type === "date") {
      return dayjsValue.format("YYYY-MM-DD");
    }
    if (type === "datetime") {
      return dayjsValue.format("YYYY-MM-DD HH:mm:ss");
    }
    return value;
  };
  const handleSubmit = () => {
    const creatingRow = table.getState().creatingRow;
    const formattedValues = {};
    table.options.columns.forEach((column) => {
      const value = creatingRow[column.accessorKey];
      if (column.type === "date" || column.type === "datetime") {
        formattedValues[column.accessorKey] = formatDateValue(
          value,
          column.type
        );
      } else {
        formattedValues[column.accessorKey] = value;
      }
    });
    handleCreateProcess({ values: formattedValues, table });
  };
  const renderField = (column) => {
    if (exlucdColumns.includes(column.accessorKey)) {
      return null;
    }
    if (!column.accessorKey) return null;
    const currentValue =
      table.getState().creatingRow?.[column.accessorKey] || "";
    const handleChange = (value) => {
      table.setCreatingRow({
        ...table.getState().creatingRow,
        [column.accessorKey]: value,
      });
    };
    switch (column.type) {
      case "select":
        const options = selectData[column.selectKey] || [];
        return (
          <FormControl fullWidth sx={commonSx} key={column.accessorKey}>
            <InputLabel>{column.header}</InputLabel>
            <Select
              value={currentValue}
              label={column.header}
              onChange={(e) => handleChange(e.target.value)}
            >
              {options.map((option) => (
                <MenuItem
                  key={option[column.valueKey]}
                  value={option[column.valueKey]}
                >
                  {option[column.labelKey]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "date":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="ko"
            key={column.accessorKey}
          >
            <DatePicker
              label={column.header}
              value={currentValue || null}
              onChange={(newValue) => handleChange(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: commonSx,
                },
              }}
            />
          </LocalizationProvider>
        );
      case "datetime":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="ko"
            key={column.accessorKey}
          >
            <DateTimePicker
              label={column.header}
              value={currentValue || null}
              onChange={(newValue) => handleChange(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: commonSx,
                },
              }}
            />
          </LocalizationProvider>
        );
      default:
        return (
          <TextField
            key={column.accessorKey}
            label={column.header}
            fullWidth
            value={currentValue}
            onChange={(e) => handleChange(e.target.value)}
            sx={commonSx}
          />
        );
    }
  };
  const renderColumns = () => {
    const validColumns = table.options.columns.filter(
      (column) =>
        column.accessorKey && !exlucdColumns.includes(column.accessorKey)
    );
    if (!isTwoColumns) {
      return validColumns.map((column) => renderField(column));
    }
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {validColumns.map((column, index) => (
          <Box
            key={column.accessorKey}
            sx={{
              width: "calc(50% - 8px)",
              ...(index === validColumns.length - 1 &&
              validColumns.length % 2 === 1
                ? { width: "calc(50% - 8px)" }
                : {}),
            }}
          >
            {renderField(column)}
          </Box>
        ))}
      </Box>
    );
  };
  return (
    <Modal open={true}>
      <ModalContainer sx={{ background: COLORS.BACKGROUND_BRIGHTER }}>
        <Typography variant="h6" component="h2" mb={3}>
          변경할곳
        </Typography>
        {renderColumns()}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button
            variant="outlined"
            onClick={() => table.setCreatingRow(null)}
            color="inherit"
          >
            취소
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            추가
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
}

export default CreateTableModal;
