import {
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';

export const MoreHoriz = MoreHorizIcon;

const icons = {
  settingsIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><g fill="currentColor" class="nc-icon-wrapper"><path d="M14.25,2H3.75c-1.517,0-2.75,1.233-2.75,2.75V13.25c0,1.517,1.233,2.75,2.75,2.75h4.762c.414,0,.75-.336,.75-.75s-.336-.75-.75-.75H3.75c-.689,0-1.25-.561-1.25-1.25v-5.25H15.5v.984c0,.414,.336,.75,.75,.75s.75-.336,.75-.75V4.75c0-1.517-1.233-2.75-2.75-2.75ZM4,6c-.552,0-1-.448-1-1s.448-1,1-1,1,.448,1,1-.448,1-1,1Zm3,0c-.552,0-1-.448-1-1s.448-1,1-1,1,.448,1,1-.448,1-1,1Z"></path><path d="M17,13h-.878c-.044-.138-.098-.271-.164-.397l.62-.621c.293-.293,.293-.768,0-1.061-.294-.293-.769-.292-1.061,0l-.62,.621c-.127-.066-.259-.121-.397-.164v-.878c0-.414-.336-.75-.75-.75s-.75,.336-.75,.75v.878c-.138,.044-.271,.098-.397,.164l-.62-.621c-.292-.292-.767-.293-1.061,0-.293,.292-.293,.768,0,1.061l.62,.621c-.066,.127-.12,.259-.164,.397h-.878c-.414,0-.75,.336-.75,.75s.336,.75,.75,.75h.878c.044,.138,.098,.271,.164,.397l-.62,.621c-.293,.293-.293,.768,0,1.061,.146,.146,.339,.22,.53,.22,.192,0,.384-.073,.53-.22l.62-.621c.127,.066,.259,.121,.397,.164v.878c0,.414,.336,.75,.75,.75s.75-.336,.75-.75v-.878c.138-.044,.271-.098,.397-.164l.62,.621c.146,.146,.338,.22,.53,.22,.191,0,.384-.073,.53-.22,.293-.292,.293-.768,0-1.061l-.62-.621c.066-.127,.12-.259,.164-.397h.878c.414,0,.75-.336,.75-.75s-.336-.75-.75-.75Zm-2.25,.75c0,.551-.448,1-1,1s-1-.449-1-1,.448-1,1-1,1,.449,1,1Z" data-color="color-2"></path></g></svg>
  ),
  chartIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path d="M1 33V37C1 39.2091 2.79086 41 5 41H43C45.2091 41 47 39.2091 47 37V33H32V35C32 35.5523 31.5523 36 31 36H17C16.4477 36 16 35.5523 16 35V33H1Z" fill="currentColor"></path> <path d="M9.5 5C5.91015 5 3 7.91015 3 11.5V30H6V11.5C6 9.567 7.567 8 9.5 8H22C22 9.10457 22.8954 10 24 10C25.1046 10 26 9.10457 26 8H38.5C40.433 8 42 9.567 42 11.5V30H45V11.5C45 7.91015 42.0899 5 38.5 5H9.5Z" fill="currentColor"></path> <path d="M21 30V13H27V30H21Z" fill="currentColor" data-color="color-2"></path> <path d="M31 30V18H37V30H31Z" fill="currentColor" data-color="color-2"></path> <path d="M11 30V24H17V30H11Z" fill="currentColor" data-color="color-2"></path> </g></svg>
  ),
  kpiIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="currentColor" class="nc-icon-wrapper"><path d="M16,3A15.981,15.981,0,0,0,3.221,28.6a1,1,0,0,0,.8.4H27.98a1,1,0,0,0,.8-.4A15.857,15.857,0,0,0,32,19,16.019,16.019,0,0,0,16,3ZM15,6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-8,13a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Zm10.414.914a2,2,0,0,1-2.828,0c-.592-.593-6.78-8.689-7.483-9.61a.5.5,0,0,1,.7-.7c.922.7,9.019,6.89,9.611,7.483A2,2,0,0,1,17.414,20.414Zm5.3-7.425-.707-.707a.5.5,0,0,1,0-.707l2.122-2.121a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707l-2.122,2.121A.5.5,0,0,1,22.717,12.989ZM28.5,20h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,28.5,20Z" fill="currentColor"></path></g></svg>
  ),
  logoutIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linejoin="round" fill="currentColor" stroke-linecap="round" class="nc-icon-wrapper"><polyline points="18.5 6 22.5 10 18.5 14" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" data-color="color-2"></polyline><polyline points="14 14 14 17 9 17" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"></polyline><polyline points="2.722 2.517 9 7.016 9 22 2 17 2 2 14 2 14 6" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"></polyline><polyline points="13 10 22.5 10 22 10" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" data-color="color-2"></polyline></g></svg>
  ),
  qrIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g stroke-linecap="round" transform="translate(0.5 0.5)" fill="currentColor" stroke-linejoin="round" class="nc-icon-wrapper"><polygon fill="none" stroke="currentColor" stroke-width="3" points="18,18 2,18 2,2 18,2 18,2 "></polygon> <polyline fill="none" stroke="currentColor" stroke-width="3" points="46,38 46,30 36,30 36,27 "></polyline> <line fill="none" stroke="currentColor" stroke-width="3" x1="46" y1="46" x2="30" y2="46"></line> <polygon data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 12,12 8,12 8,8 12,8 12,12 "></polygon> <polygon fill="none" stroke="currentColor" stroke-width="3" points="46,18 30,18 30,2 46,2 46,2 "></polygon> <polygon data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 40,12 36,12 36,8 40,8 40,12 "></polygon> <polygon fill="none" stroke="currentColor" stroke-width="3" points="18,46 2,46 2,30 18,30 18,30 "></polygon> <polygon data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 12,40 8,40 8,36 12,36 12,40 "></polygon> <polygon data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 40,40 36,40 36,36 40,36 40,40 "></polygon> <polyline fill="none" stroke="currentColor" stroke-width="3" points="25,41 25,37 29,37 29,27 "></polyline> <polyline fill="none" stroke="currentColor" stroke-width="3" points="17,25 17,23 24,23 24,27 "></polyline> <polyline fill="none" stroke="currentColor" stroke-width="3" points="22,11 25,11 25,6 "></polyline> <line fill="none" stroke="currentColor" stroke-width="3" x1="43" y1="24" x2="43" y2="30"></line> <polyline fill="none" stroke="currentColor" stroke-width="3" points="2,22 2,24 8,24 "></polyline></g></svg>
  ),
  repairIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g stroke-linecap="round" transform="translate(0.5 0.5)" fill="currentColor" stroke-linejoin="round" class="nc-icon-wrapper"> <path d="M15 15L20.5 20.5" stroke="currentColor" stroke-width="3" data-color="color-2" fill="none"></path> <path d="M23.6262 17.6729L4.83678 35.07C2.45141 37.2784 2.37998 41.0254 4.67756 43.323C6.97514 45.6206 10.7221 45.5477 12.9304 43.1638L30.3379 24.3625" stroke="currentColor" stroke-width="3" fill="none" data-cap="butt"></path> <path d="M27.3806 34.8563L35.0696 43.1609C37.2779 45.5449 41.0249 45.6178 43.3224 43.3202C45.62 41.0225 45.5486 37.2755 43.1632 35.0672L37.2767 29.6168" stroke="currentColor" stroke-width="3" data-color="color-2" fill="none"></path> <path d="M37.9286 16.3571L31.6429 10.0714L37.9663 3.748C36.7343 3.27186 35.4001 3 34 3C27.9249 3 23 7.92486 23 14C23 20.0751 27.9249 25 34 25C40.0751 25 45 20.0751 45 14C45 12.5999 44.7281 11.2657 44.252 10.0337L37.9286 16.3571Z" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M30 16L32 18" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M37 37L39.5 39.5" stroke="currentColor" stroke-width="3" data-color="color-2" fill="none"></path> <path d="M3 6L6 3L15 7.5V15H7.5L3 6Z" stroke="currentColor" stroke-width="3" data-color="color-2" fill="none"></path> </g></svg>
  ),
  checklistIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g stroke-linecap="round" transform="translate(0.5 0.5)" fill="currentColor" stroke-linejoin="round" class="nc-icon-wrapper"><polygon fill="none" stroke="currentColor" stroke-width="3" points="6,2 6,2 42,2 42,46 6,46 "></polygon> <line data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" x1="35" y1="12" x2="25" y2="12"></line> <line data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" x1="35" y1="24" x2="25" y2="24"></line> <line data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" x1="35" y1="36" x2="25" y2="36"></line> <polyline data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 13,12 15,14 20,9 "></polyline> <polyline data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 13,24 15,26 20,21 "></polyline> <circle data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" cx="16" cy="36" r="3"></circle></g></svg>
  ),
  cctvIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="currentColor" class="nc-icon-wrapper"><path data-color="color-2" fill="currentColor" d="M32 30H22a1 1 0 0 1-.986-.835l-1.164-6.986 1.973-.329L22.847 28H32v2z"></path><path fill="currentColor" d="M.032 8.77L2 8.402l2.385 12.81-1.966.366z"></path><path fill="currentColor" d="M29.071 2.923a1.004 1.004 0 0 0-.418-.642.993.993 0 0 0-.748-.158l-23.3 4.339a1.002 1.002 0 0 0-.8 1.166l2.524 13.555a1.001 1.001 0 0 0 1.167.8l23.299-4.338c.543-.101.901-.623.801-1.166L29.071 2.923zM11.5 13a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 11.5 13z"></path></g></svg>
  ),
  warningIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="currentColor" class="nc-icon-wrapper"><path d="m30.464,23L19.464,3.948c-.723-1.252-2.018-2-3.464-2s-2.741.748-3.464,2L1.536,23c-.723,1.252-.723,2.748,0,4,.723,1.252,2.018,2,3.464,2h21.998c1.446,0,2.741-.748,3.464-2,.724-1.252.724-2.748,0-4Zm-15.464-14h2v10h-2v-10Zm1,15c-.827,0-1.5-.673-1.5-1.5s.673-1.5,1.5-1.5,1.5.673,1.5,1.5-.673,1.5-1.5,1.5Z" stroke-width="0" fill="currentColor"></path></g></svg>
  ),
  blueprintIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"><path d="M39,30a1,1,0,0,0,1,1h1a6.989,6.989,0,0,1,6,3.408V8a7,7,0,0,0-7.011-7A1,1,0,0,0,39,2Z" fill="currentColor" data-color="color-2"></path><path d="M41,33H39.777A2.777,2.777,0,0,1,37,30.223V7H2A1,1,0,0,0,1,8V42a1,1,0,0,0,1,1H41a5,5,0,0,0,0-10Zm-9,3a1,1,0,0,1-1,1H15a1,1,0,0,1-1-1V32a1,1,0,0,1,2,0v3H30V15H21v9a1,1,0,0,1-1,1H15a1,1,0,0,1,0-2h4V15H8V36a1,1,0,0,1-2,0V14a1,1,0,0,1,1-1H31a1,1,0,0,1,1,1Z" fill="currentColor"></path></g></svg>
  ),
  usersIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="currentColor" class="nc-icon-wrapper"><circle cx="6.5" cy="8" r="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="currentColor"></circle><circle cx="13.5" cy="5" r="2" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-color="color-2"></circle><path d="m18.16,12.226c-.744-1.96-2.573-3.226-4.66-3.226-1.509,0-2.876.669-3.803,1.776,1.498.77,2.699,2.071,3.332,3.74.058.153.092.309.127.465.115.005.229.02.344.02,1.297,0,2.594-.299,3.881-.898.711-.331,1.053-1.155.778-1.876Z" fill="currentColor" stroke-width="0" data-color="color-2"></path><path d="m11.16,15.226c-.744-1.96-2.573-3.226-4.66-3.226s-3.916,1.266-4.66,3.226c-.275.722.067,1.546.778,1.877,1.288.599,2.584.898,3.881.898s2.594-.299,3.881-.898c.711-.331,1.053-1.155.778-1.876Z" stroke-width="0" fill="currentColor"></path></g></svg>
  ),
  userIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path fill-rule="evenodd" clip-rule="evenodd" d="M24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2ZM30.5 19.5C30.5 23.0899 27.5899 26 24 26C20.4101 26 17.5 23.0899 17.5 19.5C17.5 15.9101 20.4101 13 24 13C27.5899 13 30.5 15.9101 30.5 19.5ZM24 43C29.4204 43 34.3106 40.7302 37.7722 37.0893C35.0425 32.2613 29.9018 29 24 29C18.0981 29 12.9574 32.2613 10.2278 37.0892C13.6893 40.7302 18.5796 43 24 43Z" fill="currentColor"></path> </g></svg>
  ),
  userEditIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path fill-rule="evenodd" clip-rule="evenodd" d="M38.576 26.924C40.3851 25.1149 43.3182 25.1149 45.1273 26.924C46.9363 28.7331 46.9363 31.6662 45.1273 33.4753L33.0513 45.5514L25.8919 46.1593L26.5 39L38.576 26.924Z" fill="currentColor" data-color="color-2"></path> <path d="M34.829 26.4282C31.8082 24.2733 28.0525 23 24 23C14.1172 23 6 30.5723 6 40.0375V40.7923L6.73105 40.9647C12.2027 42.2548 17.6762 42.9316 23.1498 42.9951L23.6035 37.6538L34.829 26.4282Z" fill="currentColor"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M16 12C16 7.58172 19.5817 4 24 4C28.4183 4 32 7.58172 32 12C32 16.4183 28.4183 20 24 20C19.5817 20 16 16.4183 16 12Z" fill="currentColor"></path> </g></svg>
  ),
  editIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"><path data-color="color-2" d="M16.293,23.293c-.117,.117-.203,.261-.251,.42l-3,10c-.159,.529,.142,1.086,.671,1.245,.187,.056,.387,.056,.574,0l10-3c.159-.048,.303-.134,.42-.251L41,15.414l-8.414-8.414L16.293,23.293Z" fill="currentColor"></path><path data-color="color-2" d="M39.707,1.293c-.391-.39-1.024-.39-1.414,0l-4.293,4.293,8.414,8.414,4.293-4.293c.39-.39,.39-1.023,0-1.414l-7-7Z" fill="currentColor"></path><path d="M40,27c-.553,0-1,.448-1,1v12c0,1.654-1.346,3-3,3H8c-1.654,0-3-1.346-3-3V12c0-1.654,1.346-3,3-3h12c.553,0,1-.448,1-1s-.447-1-1-1H8c-2.757,0-5,2.243-5,5v28c0,2.757,2.243,5,5,5h28c2.757,0,5-2.243,5-5v-12c0-.552-.447-1-1-1Z" fill="currentColor"></path></g></svg>
  ),
  viewIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><g fill="currentColor" class="nc-icon-wrapper"><path d="M13.25,17H4.75c-.414,0-.75-.336-.75-.75s.336-.75,.75-.75H13.25c.414,0,.75,.336,.75,.75s-.336,.75-.75,.75Z" data-color="color-2"></path><path d="M13.75,2H4.25c-1.517,0-2.75,1.233-2.75,2.75v6.5c0,1.517,1.233,2.75,2.75,2.75H13.75c1.517,0,2.75-1.233,2.75-2.75V4.75c0-1.517-1.233-2.75-2.75-2.75Zm-1.059,6.891c-.54,.695-1.737,1.859-3.691,1.859s-3.151-1.164-3.691-1.858c0,0,0,0,0,0-.413-.533-.413-1.249,0-1.782,.54-.695,1.737-1.859,3.691-1.859s3.151,1.164,3.691,1.858c.414,.534,.414,1.25,0,1.783Z"></path><circle cx="9" cy="8" r="1.5"></circle></g></svg>
  ),
  deleteIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0834 19H8.91699L10.6353 40.4785C10.8847 43.5967 13.488 46 16.6162 46H31.3842C34.5124 46 37.1156 43.5967 37.3651 40.4785L39.0834 19ZM17.8803 24.3894L19.1104 40.3805L16.1192 40.6106L14.8892 24.6195L17.8803 24.3894ZM25.5 24.5V40.5H22.5V24.5H25.5ZM31.8803 40.6106L33.1104 24.6195L30.1192 24.3894L28.8892 40.3805L31.8803 40.6106Z" fill="currentColor"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M21 5C20.4477 5 20 5.44772 20 6V10.5H17V6C17 3.79086 18.7909 2 21 2H27C29.2091 2 31 3.79086 31 6V10.5H28V6C28 5.44772 27.5523 5 27 5H21Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M5 13C5 10.2386 7.23858 8 10 8H38C40.7614 8 43 10.2386 43 13V16H5V13Z" fill="currentColor" data-color="color-2"></path> </g></svg>
  ),
  shieldIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="currentColor" class="nc-icon-wrapper"><path d="m16,.969L3,4.219v12.781c0,9.61,12.166,13.776,12.684,13.949l.316.105.316-.105c.518-.173,12.684-4.338,12.684-13.949V4.219L16,.969Zm-2.459,20.985l-4.955-4.955,1.414-1.414,3.459,3.459,8.462-9.458,1.491,1.333-9.872,11.033Z" stroke-width="0" fill="currentColor"></path></g></svg>
  ),
  folderIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"><path d="M9.44219 17L2.13282 38.0143C1.45472 39.9639 2.90222 41.9999 4.96631 41.9999L32.9452 41.9999C35.4992 41.9999 37.7732 40.3832 38.6122 37.971L45.9065 17H9.44219Z" fill="currentColor"></path> <path d="M2 11C2 7.68629 4.68629 5 8 5H18.3205L25.3205 10H35.0552C37.6722 10 39.8913 11.6735 40.7139 14H9.44231C8.16534 14 7.02833 14.8083 6.60882 16.0144L2 29.2647V11Z" fill="currentColor" data-color="color-2"></path> </g></svg>
  ),
  databaseIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path d="M11.6464 3.81437C14.8553 2.6818 19.2231 2 24 2C28.7769 2 33.1447 2.6818 36.3536 3.81437C37.9536 4.37906 39.3195 5.07459 40.3047 5.89673C41.2844 6.71418 42 7.75815 42 9C42 10.2419 41.2844 11.2858 40.3047 12.1033C39.3195 12.9254 37.9536 13.6209 36.3536 14.1856C33.1447 15.3182 28.7769 16 24 16C19.2231 16 14.8553 15.3182 11.6464 14.1856C10.0464 13.6209 8.68051 12.9254 7.69526 12.1033C6.71563 11.2858 6 10.2419 6 9C6 7.75815 6.71563 6.71418 7.69526 5.89673C8.68051 5.07459 10.0464 4.37906 11.6464 3.81437Z" fill="currentColor" data-color="color-2"></path> <path d="M6 29.1002V39.3158C6 40.5369 6.74729 41.5408 7.72294 42.3087C8.71091 43.0863 10.078 43.7441 11.6766 44.2787C14.8844 45.3512 19.2462 46 24 46C28.7538 46 33.1156 45.3512 36.3234 44.2787C37.922 43.7441 39.2891 43.0863 40.2771 42.3087C41.2527 41.5408 42 40.5369 42 39.3158V29.1002C40.9152 30.1093 39.4418 30.9666 37.7263 31.6672C34.1608 33.1234 29.3077 34 24 34C18.6923 34 13.8392 33.1234 10.2737 31.6672C8.55816 30.9666 7.0848 30.1093 6 29.1002Z" fill="currentColor"></path> <path d="M6 26.1002V14.1002C7.0848 15.1093 8.55816 15.9666 10.2737 16.6673C13.8392 18.1234 18.6923 19 24 19C29.3077 19 34.1608 18.1234 37.7263 16.6673C39.4418 15.9666 40.9152 15.1093 42 14.1002V26.1002C40.9152 27.1093 39.4418 27.9666 37.7263 28.6673C34.1608 30.1234 29.3077 31 24 31C18.6923 31 13.8392 30.1234 10.2737 28.6673C8.55816 27.9666 7.0848 27.1093 6 26.1002Z" fill="currentColor"></path> </g></svg>
  ),
  gearIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9269 2H21.0731L18.9719 7.25377C17.8317 7.59476 16.7413 8.04726 15.7109 8.60233L10.5141 6.37535L6.37535 10.5141L8.60226 15.7107C8.04706 16.7411 7.5956 17.8323 7.2542 18.9717L2 21.0731V26.9269L7.25377 29.0281C7.59476 30.1683 8.04726 31.2587 8.60233 32.2891L6.37535 37.4859L10.5141 41.6246L15.7107 39.3977C16.7411 39.9529 17.8323 40.4044 18.9717 40.7458L21.0731 46H26.9269L29.0281 40.7462C30.1683 40.4052 31.2587 39.9527 32.2891 39.3977L37.4859 41.6246L41.6246 37.4859L39.3977 32.2893C39.9529 31.2589 40.4044 30.1677 40.7458 29.0283L46 26.9269V21.0731L40.7462 18.9719C40.4052 17.8317 39.9527 16.7413 39.3977 15.7109L41.6246 10.5141L37.4859 6.37535L32.2893 8.60226C31.2589 8.04706 30.1677 7.5956 29.0283 7.2542L26.9269 2ZM33 24C33 28.9706 28.9706 33 24 33C19.0294 33 15 28.9706 15 24C15 19.0294 19.0294 15 24 15C28.9706 15 33 19.0294 33 24Z" fill="currentColor"></path> </g></svg>
  ),
  jpgIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path fill-rule="evenodd" clip-rule="evenodd" d="M9.0001 41C10.657 41 12.0001 39.6569 12.0001 38L12 28.0001L15 28L15.0001 38C15.0001 41.3137 12.3138 44 9.00007 44C5.6864 44 3.00016 41.3137 3.00016 38V36.0001H6.00016V38C6.00016 39.6568 7.34327 41 9.0001 41Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M38.5116 31C35.7414 31 33.5 33.2411 33.5 36C33.5 38.7589 35.7414 41 38.5116 41H41V38.4286H39V35.4286H44V44H38.5116C34.0897 44 30.5 40.4208 30.5 36C30.5 31.5792 34.0897 28 38.5116 28H41V31H38.5116Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 28H23.6667C26.8883 28 29.5 30.6117 29.5 33.8333C29.5 37.055 26.8883 39.6667 23.6667 39.6667H20.5V44H17.5V28ZM20.5 36.6667H23.6667C25.2315 36.6667 26.5 35.3981 26.5 33.8333C26.5 32.2685 25.2315 31 23.6667 31H20.5V36.6667Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2426 2C19.1818 2 18.1644 2.42143 17.4142 3.17157L7.17157 13.4142C6.42153 14.1643 6 15.1812 6 16.2422V25H42V7.9991C42 4.68516 39.3135 2 36 2H20.2426ZM20 16V5L9 16H20Z" fill="currentColor"></path> </g></svg>
  ),
  pngIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9999 28H20.976L27 39.4204V28H30V44H26.0239L19.9999 32.5797L20 44L17 44L16.9999 28Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M39.5116 31C36.7414 31 34.5 33.2411 34.5 36C34.5 38.7589 36.7414 41 39.5116 41H42V38.4286H40V35.4286H45V44H39.5116C35.0897 44 31.5 40.4208 31.5 36C31.5 31.5792 35.0897 28 39.5116 28H42V31H39.5116Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 28H9.66667C12.8883 28 15.5 30.6117 15.5 33.8333C15.5 37.055 12.8883 39.6667 9.66667 39.6667H6.5V44H3.5V28ZM6.5 36.6667H9.66667C11.2315 36.6667 12.5 35.3981 12.5 33.8333C12.5 32.2685 11.2315 31 9.66667 31H6.5V36.6667Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2426 2C19.1818 2 18.1644 2.42143 17.4142 3.17157L7.17157 13.4142C6.42153 14.1643 6 15.1812 6 16.2422V25H42V7.9991C42 4.68516 39.3135 2 36 2H20.2426ZM20 16V5L9 16H20Z" fill="currentColor"></path> </g></svg>
  ),
  jsonIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00002 41C7.10458 41 8 40.1046 8 39V29H11V39C11 41.7614 8.76146 44 6.00006 44C3.23861 44 1 41.7614 1 39V36.5H4V39C4 40.1046 4.89544 41 6.00002 41Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M27.018 33.1574C26.4164 33.9595 26 35.1363 26 36.5C26 37.8637 26.4164 39.0405 27.018 39.8426C27.6193 40.6443 28.3354 41 29 41C29.6646 41 30.3807 40.6443 30.982 39.8426C31.5836 39.0405 32 37.8637 32 36.5C32 35.1363 31.5836 33.9595 30.982 33.1574C30.3807 32.3557 29.6646 32 29 32C28.3354 32 27.6193 32.3557 27.018 33.1574ZM24.618 31.3574C25.6455 29.9874 27.1794 29 29 29C30.8206 29 32.3545 29.9874 33.382 31.3574C34.4091 32.7268 35 34.55 35 36.5C35 38.45 34.4091 40.2732 33.382 41.6426C32.3545 43.0126 30.8206 44 29 44C27.1794 44 25.6455 43.0126 24.618 41.6426C23.5909 40.2732 23 38.45 23 36.5C23 34.55 23.5909 32.7268 24.618 31.3574Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M35.5 29H40.4288L44 40.1793V29H47V44H42.0712L38.5 32.8207V44H35.5V29Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 29H16C13.5147 29 11.5 31.0147 11.5 33.5C11.5 35.9853 13.5147 38 16 38H18C18.8284 38 19.5 38.6716 19.5 39.5C19.5 40.3284 18.8284 41 18 41H13.5V44H18C20.4853 44 22.5 41.9853 22.5 39.5C22.5 37.0147 20.4853 35 18 35H16C15.1716 35 14.5 34.3284 14.5 33.5C14.5 32.6716 15.1716 32 16 32H20.5V29Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2426 2C19.1818 2 18.1644 2.42143 17.4142 3.17157L7.17157 13.4142C6.42153 14.1643 6 15.1812 6 16.2422V25H42V7.9991C42 4.68516 39.3135 2 36 2H20.2426ZM20 16V5L9 16H20Z" fill="currentColor"></path> </g></svg>
  ),
  configFileIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5162 29.7404L32.4123 27.5H35.5877L36.4839 29.7407C36.7907 29.8453 37.0893 29.9687 37.3781 30.1102L39.5945 29.1604L41.8396 31.4055L40.8898 33.622C41.0313 33.9106 41.1548 34.2091 41.2596 34.5162L43.5 35.4123V38.5877L41.2596 39.4838C41.1548 39.7909 41.0313 40.0894 40.8898 40.378L41.8396 42.5945L39.5945 44.8396L37.3781 43.8898C37.0893 44.0313 36.7907 44.1547 36.4839 44.2593L35.5877 46.5H32.4123L31.5162 44.2596C31.2091 44.1548 30.9106 44.0313 30.622 43.8898L28.4055 44.8396L26.1604 42.5945L27.1102 40.3781C26.9687 40.0893 26.8453 39.7907 26.7407 39.4839L24.5 38.5877V35.4123L26.7407 34.5161C26.8453 34.2093 26.9687 33.9107 27.1102 33.6219L26.1604 31.4055L28.4055 29.1604L30.622 30.1102C30.9106 29.9687 31.2091 29.8452 31.5162 29.7404ZM36 37C36 35.8954 35.1046 35 34 35C32.8954 35 32 35.8954 32 37C32 38.1046 32.8954 39 34 39C35.1046 39 36 38.1046 36 37Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2426 2C19.1818 2 18.1644 2.42143 17.4142 3.17157L7.17157 13.4142C6.42153 14.1643 6 15.1812 6 16.2422V40C6 43.3137 8.68629 46 12 46H25.3232L24.039 44.7159C23.1717 43.8485 22.9197 42.5404 23.4029 41.4129L23.4149 41.3848L23.386 41.3732C22.2469 40.9177 21.5 39.8145 21.5 38.5877V35.4123C21.5 34.1855 22.2469 33.0823 23.386 32.6268L23.4149 32.6152L23.4029 32.5871C22.9197 31.4596 23.1717 30.1515 24.039 29.2841L26.2841 27.039C27.1515 26.1717 28.4596 25.9197 29.5871 26.4029L29.6152 26.4149L29.6268 26.386C30.0823 25.2469 31.1855 24.5 32.4123 24.5H35.5877C36.8145 24.5 37.9177 25.2469 38.3732 26.386L38.3848 26.4149L38.4129 26.4029C39.5404 25.9197 40.8485 26.1717 41.7159 27.039L42 27.3232V7.9991C42 4.68516 39.3135 2 36 2H20.2426ZM20 16V5L9 16H20Z" fill="currentColor"></path> </g></svg>
  ),
  zippedFileIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path d="M21.6 37H26.4L28 45H24H20L21.6 37Z" fill="currentColor" data-color="color-2"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 10C2 6.68629 4.68629 4 8 4H19.3333L27.3333 10H40C43.3137 10 46 12.6863 46 16V36C46 39.3137 43.3137 42 40 42H30.4594L29.3429 36.4174C29.0657 35.0152 27.8348 34 26.4 34H21.6C20.165 34 18.9339 35.0156 18.657 36.4182L17.5406 42H8C4.68629 42 2 39.3137 2 36V10ZM25.5 20H22.5V24H25.5V20ZM25.5 13V17H22.5V13H25.5ZM25.5 27H22.5V31H25.5V27Z" fill="currentColor"></path> </g></svg>
  ),
  normalFileIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="currentColor" class="nc-icon-wrapper"><path d="m18,1h-10L3,6v14c0,1.654,1.346,3,3,3h12c1.654,0,3-1.346,3-3V4c0-1.654-1.346-3-3-3ZM4.829,7l4.171-4.171v4.171h-4.171Zm12.171,11H7v-2h10v2Zm0-4H7v-2h10v2Zm0-4h-5v-2h5v2Z" stroke-width="0" fill="currentColor"></path></g></svg>
  ),
};

export const Icon = ({ name, ...props }) => {
  const SvgIcon = icons[name];
  return SvgIcon ? <SvgIcon {...props} /> : null;
};