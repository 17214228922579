import { Typography, Pagination, useMediaQuery, useTheme } from '@mui/material';
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';

const CustomPagination = () => {
  // const theme = useTheme();
  // const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    // <>
    //   {isMdUp && (
    //     <Typography
    //       color="textSecondary"
    //       flexGrow={1}
    //       sx={{
    //         whiteSpace: 'nowrap',
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //       }}
    //     >
    //       {`Showing ${page * 10 + 1} to ${Math.min((page + 1) * 10, apiRef.current.state.pagination.rowCount)} of ${apiRef.current.state.pagination.rowCount} entries`}
    //     </Typography>
    //   )}
      <Pagination
        variant='outlined'
        shape="rounded"
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(_, newPage) => apiRef.current.setPage(newPage - 1)}
        showFirstButton
        showLastButton
      />
    // </>
  );
};

export default CustomPagination;