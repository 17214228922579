import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import { useDispatch, useSelector } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import { createBarChartOptions } from "../utils/chartConfig";

function createLineSeries(name, data, color) {
  return {
    name,
    type: "line",
    data,
    label: {
      show: false,
    },
    lineStyle: { color },
    itemStyle: { color },
    yAxisIndex: 2,
  };
}

function createCommonYAxis(name, min, max, interval) {
  return {
    name,
    type: "value",
    show: false,
    min,
    max,
    interval,
  };
}

function createLineSeriesWithoutLabel(name, data, color) {
  return {
    name,
    type: "line",
    data,
    label: {
      show: false,
      // position: "top",
      // color: "black",
      // backgroundColor: "white",
      // borderColor: color,
      // borderWidth: 1,
      // borderRadius: 5,
      // padding: [1, 1],
      // formatter: function (params) {
      //   return params.value + "%";
      // },
      // fontSize: 10,
    },
    lineStyle: { color },
    itemStyle: { color },
    yAxisIndex: 2,
  };
}

function createChartOptions(title, xAxisData, series, yAxis, overlayHandler) {
  return createBarChartOptions(
    title,
    xAxisData,
    series,
    yAxis,
    overlayHandler,
    false,
    true
  );
}
function createCommonSeries(name, data, color, labelPosition = "insideTop") {
  return {
    name,
    type: "bar",
    data,
    color,
    label: {
      show: true,
      position: labelPosition,
      color: "white",
      formatter: function (params) {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      fontSize: 10,
    },
  };
}

export const useProductionPlayMonthly = () => {
  const [productionMonthOpt, setProductionMonthOpt] = useState([]);
  const [productionMonthBigOpt, setProductionMonthBigOpt] = useState([]);
  const [productionTitle, setProductionTitle] = useState([]);
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(API_URL + "/data/getProductionPlanMonthly");
        const optObj = {};
        const weekNameSet = [...new Set(res.data.map((i) => i.week_name))];
        optObj.monthOption1 = createChartOptions(
          "10월 생산실적(프레스 라인)",
          weekNameSet,
          [
            createCommonSeries(
              "생산계획",
              res.data
                .sort((a, b) => {
                  if (a.week_name < b.week_name) return -1;
                  if (a.week_name > b.week_name) return 1;
                  return 0;
                })
                .filter((item) => item.line_name === "프레스 라인")
                .map((c) => c.planned_qty),
              "#156082"
            ),
            {
              ...createCommonSeries(
                "생산실적",
                res.data
                  .sort((a, b) => {
                    if (a.week_name < b.week_name) return -1;
                    if (a.week_name > b.week_name) return 1;
                    return 0;
                  })
                  .filter((item) => item.line_name === "프레스 라인")
                  .map((c) => c.finished_qty),
                "#E97132"
              ),
            },
            createLineSeries(
              "달성율",
              res.data
                .sort((a, b) => {
                  if (a.week_name < b.week_name) return -1;
                  if (a.week_name > b.week_name) return 1;
                  return 0;
                })
                .filter((item) => item.line_name === "프레스 라인")
                .map((item) =>
                  ((item.finished_qty / item.planned_qty) * 100).toFixed(1)
                )
                .slice(0, 3),
              "#C2F1C8"
            ),
          ],
          [
            createCommonYAxis("생산계획", 0, 130000, 10000),
            createCommonYAxis("생산실적", 0, 130000, 10000),
            createCommonYAxis("달성율", 80, 120, 5),
          ],
          () => handleOverlay("monthOption1")
        );
        optObj.monthOption2 = createChartOptions(
          "10월 생산실적(사출 라인)",
          weekNameSet,
          [
            createCommonSeries(
              "생산계획",
              res.data
                .sort((a, b) => {
                  if (a.week_name < b.week_name) return -1;
                  if (a.week_name > b.week_name) return 1;
                  return 0;
                })
                .filter((item) => item.line_name === "사출 라인")
                .map((c) => c.planned_qty),
              "#156082"
            ),
            {
              ...createCommonSeries(
                "생산실적",
                res.data
                  .sort((a, b) => {
                    if (a.week_name < b.week_name) return -1;
                    if (a.week_name > b.week_name) return 1;
                    return 0;
                  })
                  .filter((item) => item.line_name === "사출 라인")
                  .map((c) => c.finished_qty),
                "#E97132"
              ),
            },
            createLineSeries(
              "달성율",
              res.data
                .sort((a, b) => {
                  if (a.week_name < b.week_name) return -1;
                  if (a.week_name > b.week_name) return 1;
                  return 0;
                })
                .filter((item) => item.line_name === "사출 라인")
                .map((item) =>
                  ((item.finished_qty / item.planned_qty) * 100).toFixed(1)
                )
                .slice(0, 3),
              "#C2F1C8"
            ),
          ],
          [
            createCommonYAxis("생산계획", 0, 22000, 1000),
            createCommonYAxis("생산실적", 0, 22000, 1000),
            createCommonYAxis("달성율", 0, 180, 5),
          ],
          () => handleOverlay("monthOption2")
        );
        optObj.monthOption3 = createChartOptions(
          "10월 생산실적(단조 라인)",
          weekNameSet,
          [
            createCommonSeries(
              "생산계획",
              res.data
                .sort((a, b) => {
                  if (a.week_name < b.week_name) return -1;
                  if (a.week_name > b.week_name) return 1;
                  return 0;
                })
                .filter((item) => item.line_name === "단조 라인")
                .map((c) => c.planned_qty),
              "#156082"
            ),
            {
              ...createCommonSeries(
                "생산실적",
                res.data
                  .sort((a, b) => {
                    if (a.week_name < b.week_name) return -1;
                    if (a.week_name > b.week_name) return 1;
                    return 0;
                  })
                  .filter((item) => item.line_name === "단조 라인")
                  .map((c) => c.finished_qty),
                "#E97132"
              ),
            },
            createLineSeries(
              "달성율",
              res.data
                .sort((a, b) => {
                  if (a.week_name < b.week_name) return -1;
                  if (a.week_name > b.week_name) return 1;
                  return 0;
                })
                .filter((item) => item.line_name === "단조 라인")
                .map((item) =>
                  ((item.finished_qty / item.planned_qty) * 100).toFixed(1)
                )
                .slice(0, 3),
              "#C2F1C8"
            ),
          ],
          [
            createCommonYAxis("생산계획", 0, 270000, 10000),
            createCommonYAxis("생산실적", 0, 270000, 10000),
            createCommonYAxis("달성율", 80, 120, 5),
          ],
          () => handleOverlay("monthOption3")
        );
        optObj.monthOption4 = createChartOptions(
          "10월 생산실적(SMT 라인)",
          weekNameSet,
          [
            createCommonSeries(
              "생산계획",
              res.data
                .sort((a, b) => {
                  if (a.week_name < b.week_name) return -1;
                  if (a.week_name > b.week_name) return 1;
                  return 0;
                })
                .filter((item) => item.line_name === "SMT 라인")
                .map((c) => c.planned_qty),
              "#156082"
            ),
            {
              ...createCommonSeries(
                "생산실적",
                res.data
                  .sort((a, b) => {
                    if (a.week_name < b.week_name) return -1;
                    if (a.week_name > b.week_name) return 1;
                    return 0;
                  })
                  .filter((item) => item.line_name === "SMT 라인")
                  .map((c) => c.finished_qty),
                "#E97132"
              ),
            },
            createLineSeries(
              "달성율",
              res.data
                .sort((a, b) => {
                  if (a.week_name < b.week_name) return -1;
                  if (a.week_name > b.week_name) return 1;
                  return 0;
                })
                .filter((item) => item.line_name === "SMT 라인")
                .map((item) =>
                  ((item.finished_qty / item.planned_qty) * 100).toFixed(1)
                )
                .slice(0, 3),
              "#C2F1C8"
            ),
          ],
          [
            createCommonYAxis("생산계획", 0, 20000, 1000),
            createCommonYAxis("생산실적", 0, 20000, 1000),
            createCommonYAxis("달성율", 80, 120, 5),
          ],
          () => handleOverlay("monthOption4")
        );
        setProductionMonthOpt(optObj);
        const createWorkerBigOptions = (options, handleOverlay) => {
          return Object.entries(options).reduce((acc, [key, value], index) => {
            acc[`monthBigOption${index + 1}`] = createBarChartOptions(
              value.title.text,
              value.xAxis.data,
              value.series,
              value.yAxis,
              () => handleOverlay(`monthOption${index + 1}`),
              true,
              true
            );
            return acc;
          }, {});
        };
        const monthBig = createWorkerBigOptions(optObj, handleOverlay);
        setProductionMonthBigOpt(monthBig);
        const totalPlannedQty = res.data
          .map((i) => i.planned_qty)
          .reduce((acc, cur) => acc + cur)
          .toLocaleString("en-US");
        const totalFinishedQty = res.data
          .map((i) => i.finished_qty)
          .reduce((acc, cur) => acc + cur)
          .toLocaleString("en-US");
        const totalRate = (
          (Number(totalFinishedQty.replaceAll(",", "")) /
            Number(totalPlannedQty.replaceAll(",", ""))) *
          100
        ).toFixed(1);
        const title = [
          {
            id: 1,
            label: "10월 계획수량",
            value: totalPlannedQty,
            color: "white",
          },
          {
            id: 2,
            label: "10월 실적수량",
            value: totalFinishedQty,
            color: "white",
          },
          {
            id: 3,
            label: "10월 달성율",
            value: totalRate + "%",
            color: "white",
          },
        ];
        setProductionTitle(title);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);
  return {
    productionMonthOpt,
    productionMonthBigOpt,
    productionTitle,
  };
};
