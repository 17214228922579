import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { Avatar, Box, Typography, useTheme, IconButton, Tooltip, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import { MoreHoriz, Icon } from '../icons';
import { MenuOpen, Menu as MenuIcon } from '@mui/icons-material';
import { hexToRGBA } from '../../utils/hex-to-rgba';

import { useAuth } from "../../hooks/useAuth";

const EditSidebar = ({ collapsed, setCollapsed }) => {
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { group_code, project_code } = useParams();
  const [queryParams, setQueryParams] = useState({});
  const [hasImage, setHasImage] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const auth = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setQueryParams({
      projectName: params.get("projectName"),
      groupName: params.get("groupName"),
      projectId: params.get("pid"),
    });
  }, [location.search]);

  const handleMenuClick = (path) => {
    // 페이지 이동 시 group_code, project_code, query params 포함
    const url = `/edit/${path}/${group_code}/${project_code}?projectName=${queryParams.projectName}&groupName=${queryParams.groupName}&pid=${queryParams.projectId}`;
    navigate(url);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    navigate('/login');
  };

  const sidebarTheme = theme.palette.mode === 'light' ? {
    sidebar: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    menu: {
      menuContent: theme.palette.background.default,
      icon: theme.palette.primary.main,
      hover: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.primary,
      },
      disabled: {
        color: theme.palette.action.disabled,
      },
    },
  } : {
    sidebar: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    menu: {
      menuContent: theme.palette.background.paper,
      icon: theme.palette.primary.light,
      hover: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.primary,
      },
      disabled: {
        color: theme.palette.action.disabled,
      },
    },
  };

  const menuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
    },
    icon: {
      color: sidebarTheme.menu.icon,
      '& svg': {
        width: '20px',
        height: '20px',
      },
      [`&.${menuClasses.disabled}`]: {
        color: sidebarTheme.menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRGBA(sidebarTheme.menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: sidebarTheme.menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRGBA(sidebarTheme.menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: sidebarTheme.menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  if (!user) {
    return null;
  }

  return (
    <Sidebar
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={() => setToggled(false)}
      backgroundColor={hexToRGBA(sidebarTheme.sidebar.backgroundColor, hasImage ? 0.9 : 1)}
      rootStyles={{
        height: '100%',
        color: sidebarTheme.sidebar.color,
        width: collapsed ? '80px' : '190px',
        minWidth: collapsed ? '80px' : '190px',
        transition: 'width 0.3s'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 51px)', overflowY: 'auto' }}>
        <div style={{ minHeight: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <MenuIcon /> : <MenuOpen />}
          </IconButton>
        </div>
        <div style={{ flex: 1 }}>
          {collapsed ? (
            <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MoreHoriz />
            </div>
          ) : (
            <div style={{ padding: '0 24px', marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
              >
                파일
              </Typography>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <Tooltip title="프로젝트 파일 관리" placement="right" arrow>
              <MenuItem 
                icon={<Icon name="folderIcon" />}
                onClick={() => handleMenuClick("file")}
                style={{
                  backgroundColor: location.pathname.startsWith('/edit/file') ? theme.palette.action.selected : null,
                }}
              >
                프로젝트 파일 관리
              </MenuItem>
            </Tooltip>
          </Menu>
            
          {collapsed ? (
            <div style={{ marginBottom: '8px', marginTop: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MoreHoriz />
            </div>
          ) : (
            <div style={{ padding: '0 24px', marginBottom: '8px', marginTop: '32px', display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
              >
                데이터베이스
              </Typography>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <Tooltip title="테이블 관리" placement="right" arrow>
              <SubMenu label="테이블 관리" icon={<Icon name="databaseIcon" />}>
                <MenuItem
                  onClick={() => handleMenuClick("machinestatus")}
                  style={{
                    backgroundColor: location.pathname.startsWith('/edit/machinestatus') ? theme.palette.action.selected : null,
                  }}
                > 
                  MachineStatus
                </MenuItem>
                <MenuItem
                  onClick={() => handleMenuClick("statusmaster")}
                  style={{
                    backgroundColor: location.pathname.startsWith('/edit/statusmaster') ? theme.palette.action.selected : null,
                  }}
                > 
                  StatusMaster
                </MenuItem>
              </SubMenu>
            </Tooltip>  
          </Menu>
        </div>
      </div>
      {collapsed ? (
        <Box sx={{ height : '41px', display: 'flex', alignItems: 'center', justifyContent : 'center', mb: '10px', cursor: 'pointer' }} onClick={handleAvatarClick}>
          <Avatar sx={{ width: 38, height: 38 }}>
            <Icon name="userIcon" />
          </Avatar>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', pr: '20px', pl: '20px', mb: '10px', cursor: 'pointer' }} onClick={handleAvatarClick}>
          <Avatar sx={{ width: 38, height: 38, mr: 6 }}>
            <Icon name="userIcon" />
          </Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h6'>{user.user_name}</Typography>
            <Typography variant='body2' sx={{ color: 'text.disabled' }}>
              {user.group_name}
            </Typography>
          </Box>
        </Box>
      )}
      <MuiMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MuiMenuItem onClick={handleLogout}>
          <Icon name="logoutIcon" style={{ marginRight: '10px', width: '20px', height: '20px', color: theme.palette.text.secondary }} />
          <Typography variant="body2" color="text.primary">
            로그아웃
          </Typography>
        </MuiMenuItem>
      </MuiMenu>
    </Sidebar>
  );
};

export default EditSidebar;