export const COLORS = {
  PRIMARY: "#eb5e28",
  SECONDARY: "#e8beae",
  BACKGROUND: "#fffcf2",
  BACKGROUND_BRIGHTER: "#fcfcfa",
  BACKGROUND_NORMAL: "#ccc5b9",
  BACKGROUND_DARK: "#403D39",
  BACKGROUND_DARKER: "#252422",
  ACCENT: "#403D39",
  TEXT_BRIGHT: "#fffcf2",
  TEXT_NORMAL: "#ccc5b9",
  TEXT_DARK: "#403D39",
  TEXT_DARKER: "#252422",
  DELETE: "#e35d5d",
  DELETE_HOVER: "#d34545",
  ADD: "#4caf50",
  ADD_HOVER: "#388e3c",
  TABLE_BORDER: "#e5e5e5",
};
