import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import { useDispatch, useSelector } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import { createBarChartOptions } from "../utils/chartConfig";
function createLineSeries(name, data, color) {
  return {
    name,
    type: "line",
    data,
    label: {
      show: false,
    },
    lineStyle: { color },
    itemStyle: { color },
    yAxisIndex: 2,
  };
}

function createCommonYAxis(name, min, max, interval) {
  return {
    name,
    type: "value",
    show: false,
    min,
    max,
    interval,
  };
}

function createLineSeriesWithoutLabel(name, data, color) {
  return {
    name,
    type: "line",
    data,
    label: {
      show: false,
      // position: "top",
      // color: "black",
      // backgroundColor: "white",
      // borderColor: color,
      // borderWidth: 1,
      // borderRadius: 5,
      // padding: [1, 1],
      // formatter: function (params) {
      //   return params.value + "%";
      // },
      // fontSize: 10,
    },
    lineStyle: { color },
    itemStyle: { color },
    yAxisIndex: 2,
  };
}

function createChartOptions(title, xAxisData, series, yAxis, overlayHandler) {
  return createBarChartOptions(
    title,
    xAxisData,
    series,
    yAxis,
    overlayHandler,
    false,
    true
  );
}
function createCommonSeries(name, data, color, labelPosition = "insideTop") {
  return {
    name,
    type: "bar",
    data,
    color,
    label: {
      show: true,
      position: labelPosition,
      color: "white",
      formatter: function (params) {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      fontSize: 10,
    },
  };
}

export const useCapability = () => {
  const [capOpt, setCapOpt] = useState([]);
  const [capBigOpt, setCapBigOpt] = useState([]);
  const [capTitle, setCapTitle] = useState([]);
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(API_URL + "/data/getDataProcessCapability");
        const monthSet = [
          ...new Set(
            res.data.map((c) => new Date(c.month).getMonth() + 1 + "월")
          ),
        ];
        const optOptions = {};
        optOptions.capOpt1 = createChartOptions(
          "ESE 외경 공정능력",
          monthSet,
          [
            createCommonSeries(
              "Cp",
              res.data.map((c) => Number(c.outer_diameter_cp).toFixed(2)),
              "#A02B93"
            ),
            createCommonSeries(
              "Cpk",
              res.data.map((c) => Number(c.outer_diameter_cpk).toFixed(2)),
              "#46B1E1"
            ),
            createLineSeries(
              "기준",
              res.data.map((c) => Number(c.outer_diameter_standard).toFixed(2)),
              "#E97132"
            ),
          ],
          [
            createCommonYAxis("Cp", 0, 2, 1),
            createCommonYAxis("Cpk", 0, 2, 1),
            createCommonYAxis("기준", 0, 2, 1),
          ],
          () => handleOverlay("capOpt1")
        );
        optOptions.capOpt2 = createChartOptions(
          "ESE 두께 공정능력",
          monthSet,
          [
            createCommonSeries(
              "Cp",
              res.data.map((c) => Number(c.thick_cp).toFixed(2)),
              "#A02B93"
            ),
            createCommonSeries(
              "Cpk",
              res.data.map((c) => Number(c.thick_cpk).toFixed(2)),
              "#46B1E1"
            ),
            createLineSeries(
              "기준",
              res.data.map((c) => Number(c.thick_standard).toFixed(2)),
              "#E97132"
            ),
          ],
          [
            createCommonYAxis("Cp", 0, 2, 1),
            createCommonYAxis("Cpk", 0, 2, 1),
            createCommonYAxis("기준", 0, 2, 1),
          ],
          () => handleOverlay("capOpt2")
        );
        optOptions.capOpt3 = createChartOptions(
          "ESE 전장 공정능력",
          monthSet,
          [
            createCommonSeries(
              "Cp",
              res.data.map((c) => Number(c.whole_length_cp).toFixed(2)),
              "#A02B93"
            ),
            createCommonSeries(
              "Cpk",
              res.data.map((c) => Number(c.whole_length_cpk).toFixed(2)),
              "#46B1E1"
            ),
            createLineSeries(
              "기준",
              res.data.map((c) => Number(c.whole_length_standard).toFixed(2)),
              "#E97132"
            ),
          ],
          [
            createCommonYAxis("Cp", 0, 2, 1),
            createCommonYAxis("Cpk", 0, 2, 1),
            createCommonYAxis("기준", 0, 2, 1),
          ],
          () => handleOverlay("capOpt3")
        );
        optOptions.capOpt4 = createChartOptions(
          "ESE 폭 공정능력",
          monthSet,
          [
            createCommonSeries(
              "Cp",
              res.data.map((c) => Number(c.width_cp).toFixed(2)),
              "#A02B93"
            ),
            createCommonSeries(
              "Cpk",
              res.data.map((c) => Number(c.width_cpk).toFixed(2)),
              "#46B1E1"
            ),
            createLineSeries(
              "기준",
              res.data.map((c) => Number(c.width_standard).toFixed(2)),
              "#E97132"
            ),
          ],
          [
            createCommonYAxis("Cp", 0, 2, 1),
            createCommonYAxis("Cpk", 0, 2, 1),
            createCommonYAxis("기준", 0, 2, 1),
          ],
          () => handleOverlay("capOpt4")
        );
        setCapOpt(optOptions);
        const createWorkerBigOptions = (options, handleOverlay) => {
          return Object.entries(options).reduce((acc, [key, value], index) => {
            acc[`capBigOpt${index + 1}`] = createBarChartOptions(
              value.title.text,
              value.xAxis.data,
              value.series,
              value.yAxis,
              () => handleOverlay(`capOpt${index + 1}`),
              true,
              true
            );
            return acc;
          }, {});
        };

        const capBig = createWorkerBigOptions(optOptions, handleOverlay);
        const titleCp = (
          Number(
            (
              (res.data
                .map((i) => i.outer_diameter_cp)
                .reduce((acc, cur) => acc + cur) /
                monthSet.length) *
              0.4
            ).toFixed(1)
          ) +
          Number(
            (
              (res.data.map((i) => i.thick_cp).reduce((acc, cur) => acc + cur) /
                monthSet.length) *
              0.3
            ).toFixed(1)
          ) +
          Number(
            (
              (res.data
                .map((i) => i.whole_length_cp)
                .reduce((acc, cur) => acc + cur) /
                monthSet.length) *
              0.2
            ).toFixed(1)
          ) +
          Number(
            (
              (res.data.map((i) => i.width_cp).reduce((acc, cur) => acc + cur) /
                monthSet.length) *
              0.1
            ).toFixed(1)
          )
        ).toFixed(1);
        const titleCpk = (
          Number(
            (
              (res.data
                .map((i) => i.outer_diameter_cpk)
                .reduce((acc, cur) => acc + cur) /
                monthSet.length) *
              0.4
            ).toFixed(1)
          ) +
          Number(
            (
              (res.data
                .map((i) => i.thick_cpk)
                .reduce((acc, cur) => acc + cur) /
                monthSet.length) *
              0.3
            ).toFixed(1)
          ) +
          Number(
            (
              (res.data
                .map((i) => i.whole_length_cpk)
                .reduce((acc, cur) => acc + cur) /
                monthSet.length) *
              0.2
            ).toFixed(1)
          ) +
          Number(
            (
              (res.data
                .map((i) => i.width_cpk)
                .reduce((acc, cur) => acc + cur) /
                monthSet.length) *
              0.1
            ).toFixed(1)
          )
        ).toFixed(1);
        const title = [
          {
            id: 1,
            label: "ESE 품목",
            color: "white",
            value: "공정능력",
          },
          {
            id: 2,
            label: "Cp",
            color: "white",
            value: titleCp,
          },
          {
            id: 3,
            label: "Cpk",
            color: "white",
            value: titleCpk,
          },
        ];
        setCapTitle(title);
        setCapBigOpt(capBig);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);
  return { capOpt, capBigOpt, capTitle };
};
