import styled, { css } from "styled-components";
import { COLORS } from "../../styles/colors";

const buttonVariants = {
  default: css`
    background-color: ${COLORS.PRIMARY};
    color: white;
    &:hover {
      background-color: ${COLORS.SECONDARY};
    }
  `,
  delete: css`
    background-color: ${COLORS.DELETE};
    color: white;
    font-weight: 500;
    &:hover {
      background-color: ${COLORS.DELETE_HOVER};
    }
  `,
  add: css`
    background-color: ${COLORS.ADD};
    color: white;
    font-weight: 500;
    &:hover {
      background-color: ${COLORS.ADD_HOVER};
    }
  `,
};

const StyledButton = styled.button`
  transition: all 0.3s;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
  }
  ${({ variant }) => buttonVariants[variant || "default"]}
  ${(props) => props.className}
`;

const Button = ({
  className = "",
  variant = "default",
  children,
  ...props
}) => {
  return (
    <StyledButton className={className} variant={variant} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
