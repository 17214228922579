import styled from "styled-components";

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

export const TopLogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 10px;
`;

export const BottomWrapper = styled.div`
  position: sticky;
  bottom: 0;
  border-top: 1px solid #3e3e3e;
`;
