import Button from "./Button";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const LabelButton = styled(Button)`
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #a5f3fc;
  border-radius: 0;
  border: none;

  &:hover {
    font-size: 0.875rem;
    font-weight: bold;
    background-color: #a5f3fc;
    border-radius: 0;
    border: none;
  }
`;

const SelectContainer = styled.div``;

const StyledSelect = styled.select`
  height: 48px;
  width: 120px;
  border-radius: 8px;
  background: none;
  padding: 0 12px;
  ${(props) => props.className}
`;

function SelectBox({
  label,
  options,
  handleChange,
  selectedValue,
  placeholder,
  disabled,
  className,
}) {
  return (
    <Container>
      {label && <LabelButton disabled>{label}</LabelButton>}
      <SelectContainer>
        <StyledSelect
          value={selectedValue}
          onChange={(e) => handleChange(e)}
          disabled={disabled}
          className={className}
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option
              key={`select_${option.key}`}
              value={option.key}
              data-name={option.value}
            >
              {option.value}
            </option>
          ))}
        </StyledSelect>
      </SelectContainer>
    </Container>
  );
}

export default SelectBox;
