import { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useOutletContext } from 'react-router-dom';
import { axiosPrivate } from "../../../api/axios";
import {
  Box,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  CircularProgress
} from "@mui/material";
import { Icon } from '../../icons';
import CustomAvatar from "../../mui/avartar";
import ConfirmDialog from "../../CommonDialog/ConfirmDialog";

const FileManagementPage = () => {
  const [projectId, setProjectId] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // 삭제 확인창
  const [selectedFile, setSelectedFile] = useState(null);
  const location = useLocation();
  const { groupName, projectName } = useOutletContext();
  const fileInputRef = useRef(null);

  // 프로젝트 ID 쿼리 파라미터에서 추출
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setProjectId(queryParams.get("pid"));
  }, [location.search]);

  const fetchFileList = useCallback(async () => {
    if (!projectId) return;
    setLoading(true);
    try {
      const response = await axiosPrivate.post("/file/getProjectFileList", {
        project_id: projectId,
        group_name: groupName,
        project_name: projectName
      });
      setFileList(response.data.files || []);
    } catch (error) {
      console.error("파일 목록 조회 실패:", error);
      const errorMessage = error.response?.data?.message || "파일 목록을 조회할 수 없습니다.";
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [projectId, groupName, projectName]);  // 의존성 명시
  
  useEffect(() => {
    fetchFileList();
  }, [fetchFileList]);

  // 파일 확장자에 따라 다른 아이콘 적용 (색상 적용 여부는 추후 결정)
  const getFileIcon = (extension) => {
    const ext = extension.toLowerCase();
    const fileIconSize = "30px";
    if (ext === ".json")
      return (
        <CustomAvatar skin="light" color="primary" sx={{ mr: 4, width: 42, height: 42 }}>
          <Icon name="jsonIcon" color="primary" width={fileIconSize} height={fileIconSize} />
        </CustomAvatar>
      );
    if (ext === ".jpg" || ext === ".jpeg")
      return (
        <CustomAvatar skin="custom" color="#3479da" sx={{ mr: 4, width: 42, height: 42 }}>
          <Icon name="jpgIcon" color="primary" width={fileIconSize} height={fileIconSize} />
        </CustomAvatar>
      );
    if (ext === ".png")
      return (
        <CustomAvatar skin="custom" color="#3479da" sx={{ mr: 4, width: 42, height: 42 }}>
          <Icon name="pngIcon" color="primary" width={fileIconSize} height={fileIconSize} />
        </CustomAvatar>
      );
    if (ext === ".zip" || ext === ".rar" || ext === ".7z" || ext === ".tar")
      return (
        <CustomAvatar skin="light" color="primary" sx={{ mr: 4, width: 42, height: 42 }}>
          <Icon name="zippedFileIcon" color="primary" width={fileIconSize} height={fileIconSize} />
        </CustomAvatar>
      );
    if (ext === ".config")
      return (
        <CustomAvatar skin="light" color="primary" sx={{ mr: 4, width: 42, height: 42 }}>
          <Icon name="configFileIcon" color="primary" width={fileIconSize} height={fileIconSize} />
        </CustomAvatar>
      );
    return (
      <CustomAvatar skin="light" color="primary" sx={{ mr: 4, width: 42, height: 42 }}>
        <Icon name="normalFileIcon" color="primary" width={fileIconSize} height={fileIconSize} />
      </CustomAvatar>
    );
  };

  const uploadFiles = async (formData, overwrite = false) => {
    const response = await axiosPrivate.post("/file/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: { overwrite },
    });
    return response.data;
  };
  
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;
  
    const formData = new FormData();
    formData.append("project_id", projectId);
    formData.append("group_name", groupName);
    formData.append("project_name", projectName);
  
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
  
    setLoading(true);
    try {
      const data = await uploadFiles(formData);
      if (data.duplicates?.length) {
        const overwrite = window.confirm(
          `다음 파일들이 이미 존재합니다:\n${data.duplicates.join(", ")}\n덮어쓰시겠습니까?`
        );
  
        if (overwrite) {
          await uploadFiles(formData, true);
        } else {
          alert("파일 업로드가 취소되었습니다.");
          return;
        }
      }
      await fetchFileList();  // 업로드 후 목록 갱신
      alert("파일이 성공적으로 업로드되었습니다.");
    } catch (error) {
      console.error("파일 업로드 실패:", error);
    
      // 서버에서 반환한 메시지가 있을 경우 사용자에게 표시
      const errorMessage = error.response?.data?.message || "파일 업로드 중 오류가 발생했습니다.";
      alert(errorMessage);
    } finally {
      setLoading(false);
      event.target.value = "";  // 업로드 완료 후 input 초기화
    }
  };

  // 파일 업로드 버튼 클릭 (숨겨진 input[type=file] 트리거)
  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileDownload = async (fileName) => {
    try {
      const response = await axiosPrivate.get(`/file/download`, {
        params: {
          project_id: projectId,
          group_name: groupName,
          project_name: projectName,
          file_name: fileName,
        },
        responseType: "blob", // 파일 데이터로 반환
      });
  
      // 파일 다운로드
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("파일 다운로드 실패:", error);
      alert("파일 다운로드 중 오류가 발생했습니다.");
    }
  };

  const handleDeleteFile = async () => {
    if (!selectedFile) return;
    try {
      await axiosPrivate.delete(`/file/delete`, {
        params: {
          project_id: projectId,
          group_name: groupName,
          project_name: projectName,
          file_name: selectedFile,
        },
      });
      alert("파일이 삭제되었습니다.");
      await fetchFileList();  // 삭제 후 목록 갱신
    } catch (error) {
      console.error("파일 삭제 실패:", error);
      alert("파일 삭제 중 오류가 발생했습니다.");
    } finally {
      setDeleteDialogOpen(false);
      setSelectedFile(null);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <Grid container spacing={3} sx={{ height: "100%", p: 4 }}>
      <Grid item xs={12} sx={{ height: "100%"}}>
        <Card sx={{ height: "100%", display: 'flex', flexDirection: 'column' }}>
          <CardHeader
            title={`${projectName} 파일 관리`}
            subheader={`그룹: ${groupName}`}
            action={
              <>
                <input
                  type="file"
                  multiple
                  hidden
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={triggerFileUpload}
                >
                  파일 업로드
                </Button>
              </>
            }
          />
          <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ) : fileList.length === 0 ? (
              <Typography variant="body1" color="textSecondary" align="center">
                해당 프로젝트에 파일이 존재하지 않습니다.
              </Typography>
            ) : (
              <List>
                {fileList.map((file, index) => (
                  <ListItem key={index}>
                    <Paper
                      variant="outlined"
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1rem"
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        {getFileIcon(file.file_extension)}
                        <ListItemText
                          primary={file.file_name}
                          secondary={`수정: ${file.modified_time} / 크기: ${(
                            file.size / 1024
                          ).toFixed(2)} KB`}
                          sx={{ ml: 2 }}
                        />
                      </Box>
                      <Box display="flex" alignItems="center">
                        <ButtonGroup variant="outlined" size="small"> 
                          <Button
                            disabled={loading}
                            onClick={() => handleFileDownload(file.file_name)}
                          >
                            다운로드
                          </Button>
                          <Button
                            disabled={loading}
                            onClick={() => {
                              setSelectedFile(file.file_name);  // 선택된 파일 저장
                              setDeleteDialogOpen(true);  // 삭제 확인창 열기
                            }}
                            color="error"
                          >
                            삭제
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Paper>
                  </ListItem>
                ))}
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>
      {/* 프로젝트 삭제 확인 */}
      <ConfirmDialog
        open={deleteDialogOpen}
        onConfirm={handleDeleteFile}
        onCancel={handleCloseDeleteDialog}
        actionType={`"${selectedFile || '선택된'}" 파일을 삭제`}
        extraSentence={"삭제한 파일은 복구할 수 없습니다."}
        iconName={"deleteIcon"}
        iconColor={"error"}
        iconSize={"80px"}
      />
    </Grid>
  );
};

export default FileManagementPage;