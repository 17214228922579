import { getToken, setToken, clearToken } from "../auth/tokenStore";
import authConfig from "../auth/auth";
import { logout } from "../slice/authSlice"; // Redux logout 액션
import { store } from "../store";

export const setupAxiosInterceptors = (axiosInstance) => {
  // Request Interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response Interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // AccessToken 만료 처리
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const refreshResponse = await axiosInstance.post(authConfig.refreshTokenEndpoint, {}, { withCredentials: true });
          setToken(refreshResponse.data.accessToken);
          originalRequest.headers["authorization"] = `Bearer ${refreshResponse.data.accessToken}`;
          return axiosInstance(originalRequest); // 실패한 요청 재시도
        } catch (refreshError) {
          // RefreshToken 만료 또는 유효하지 않을 경우
          clearToken();
          store.dispatch(logout()); // Redux에서 사용자 상태 초기화
          return Promise.reject(refreshError);
        }
      }

      // Forbidden 처리 (예: 403 응답)
      if (error.response?.status === 403) {
        clearToken();
        store.dispatch(logout()); // 세션 종료
      }

      return Promise.reject(error);
    }
  );
};