import { axiosInstance } from "../api/axios";
import { useDispatch } from "react-redux";
import { setUser } from "../slice/authSlice";
import { setToken, clearToken } from "../auth/tokenStore";

const useRefreshToken = () => {
  const dispatch = useDispatch();

  const refresh = async (deviceId) => {
    if (!deviceId) {
      console.error("Device ID가 설정되지 않았습니다.");
      return;
    }

    // 요청 상태를 로컬 스토리지로 관리 (Race Condition 방지)
    const isRefreshing = localStorage.getItem("isRefreshing");

    if (isRefreshing === "true") {
      // 이미 진행 중인 요청이 있으면 대기
      return new Promise((resolve, reject) => {
        const pendingRequests = JSON.parse(localStorage.getItem("pendingRequests")) || [];
        pendingRequests.push({ resolve, reject });
        localStorage.setItem("pendingRequests", JSON.stringify(pendingRequests));
      });
    }

    localStorage.setItem("isRefreshing", "true"); // 플래그 설정
    localStorage.setItem("pendingRequests", JSON.stringify([])); // 대기 요청 초기화

    try {
      const response = await axiosInstance.post(
        "/auth/refresh",
        { device_id: deviceId }, // 전달받은 deviceId 사용
        { withCredentials: true }
      );
      setToken(response.data.accessToken);
      dispatch(setUser(response.data.user));

      // 대기 중인 요청 처리
      const pendingRequests = JSON.parse(localStorage.getItem("pendingRequests")) || [];
      pendingRequests.forEach((req) => req.resolve(response.data.accessToken));
      localStorage.setItem("pendingRequests", JSON.stringify([])); // 대기 요청 초기화

      return response.data.accessToken;
    } catch (error) {
      clearToken();

      // 대기 중인 요청 에러 처리
      const pendingRequests = JSON.parse(localStorage.getItem("pendingRequests")) || [];
      pendingRequests.forEach((req) => req.reject(error));
      localStorage.setItem("pendingRequests", JSON.stringify([])); // 초기화

      throw error;
    } finally {
      localStorage.setItem("isRefreshing", "false"); // 플래그 초기화
    }
  };

  return refresh;
};

export default useRefreshToken;