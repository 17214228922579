import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { Avatar, Box, Typography, useTheme, IconButton, Tooltip, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import { MoreHoriz, Icon } from '../icons';
import { MenuOpen, Menu as MenuIcon } from '@mui/icons-material';
import { hexToRGBA } from '../../utils/hex-to-rgba';

import { useAuth } from "../../hooks/useAuth";
import { color, height, width } from '@mui/system';

import SidebarStyles from './SidebarStyles.css'

const GroupSidebar = ({ collapsed, setCollapsed }) => {
  const user = useSelector((state) => state.auth.user);
  // const initials = user?.login_id.slice(0, 2).toUpperCase();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasImage, setHasImage] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const auth = useAuth();

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    navigate('/login');
  };

  const sidebarTheme = theme.palette.mode === 'light' ? {
    sidebar: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    menu: {
      menuContent: theme.palette.background.default,
      icon: theme.palette.primary.main,
      hover: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.primary,
      },
      disabled: {
        color: theme.palette.action.disabled,
      },
    },
  } : {
    sidebar: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    menu: {
      menuContent: theme.palette.background.paper,
      icon: theme.palette.primary.light,
      hover: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.primary,
      },
      disabled: {
        color: theme.palette.action.disabled,
      },
    },
  };

  const menuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
    },
    icon: {
      color: sidebarTheme.menu.icon,
      '& svg': {
        width: '20px',
        height: '20px',
      },
      [`&.${menuClasses.disabled}`]: {
        color: sidebarTheme.menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRGBA(sidebarTheme.menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: sidebarTheme.menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRGBA(sidebarTheme.menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: sidebarTheme.menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  if (!user) {
    return null;
  }

  return (
    <Sidebar
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={() => setToggled(false)}
      backgroundColor={hexToRGBA(sidebarTheme.sidebar.backgroundColor, hasImage ? 0.9 : 1)}
      rootStyles={{
        height: '100%',
        color: sidebarTheme.sidebar.color,
        width: '80px',
        minWidth: '80px',
        boxShadow: '0px 2px 8px rgba(47, 43, 61, 0.12)'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}>
        <div style={{ minHeight: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '14px' }}>
          <MenuIcon />
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography
              fontWeight={600}
              style={{ fontSize: '12px', opacity: 0.7, letterSpacing: '0.5px' }}
            >
              관리 메뉴
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              onClick={() => handleMenuClick('/projects')}
              style={{
                height: 'auto',
                padding: '10px 0',
                backgroundColor: location.pathname === '/projects' ? theme.palette.action.selected : null,
              }}
            >
              <Icon 
                name="blueprintIcon" 
                style={{ 
                  width: '20px', 
                  height: '20px',
                  color: location.pathname === '/projects' ? theme.palette.primary.main : 'rgba(124, 124, 124, 1)'
                }} 
              />
              <Typography variant="caption" style={{ fontSize: '10px', fontWeight: 500, opacity: 0.9 }}>
                프로젝트 관리
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuClick('/users')}
              style={{
                height: 'auto',
                padding: '10px 0',
                backgroundColor: location.pathname === '/users' ? theme.palette.action.selected : null,
              }}
            >
              <Icon 
                name="usersIcon" 
                style={{ 
                  width: '20px', 
                  height: '20px',
                  color: location.pathname === '/users' ? theme.palette.primary.main : 'rgba(124, 124, 124, 1)'
                }} 
              />
              <Typography variant="caption" style={{ fontSize: '10px', fontWeight: 500, opacity: 0.9 }}>
                유저 관리
              </Typography>
            </MenuItem>

          </Menu>
        </div>
      </div>
    </Sidebar>
  );
};

export default GroupSidebar;