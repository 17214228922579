import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_URL from "../utils/API_URL";

const initialState = {
  user: null,
  isAuthenticated: false,
  assetCode: "",
  storage: [],
  graphStatus: true,
  kpiStatus: true,
  ganttStatus: false,
  summaryItemStatus: false,
  summaryLineStatus: false,
  summaryResultStatus: false,
  simResult: false,
  lineSummaryStatus: false,
  planWeek: false,
  month: 1,
  popStatus: false,
  popShowing: "",
  simulation1: false,
  simulation2: false,
  workerStatus: false,
  chattingStatus: {
    type: "",
    status: null,
  },
  qrData: null,
};

export const fetchStorage = createAsyncThunk("auth/fetchStorage", async () => {
  const res = await axios.get(API_URL + "/data/getMasterAsset");
  const final = [...new Set(res.data[0].map((item) => item.asset_code))];
  return final;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.qrData = null;
    },
    setAssetCode(state, action) {
      state.assetCode = action.payload;
    },
    setGraphStatus(state, action) {
      state.graphStatus = action.payload;
    },
    setMonth(state, action) {
      state.month = action.payload;
    },
    setKpiStatus(state, action) {
      state.kpiStatus = action.payload;
    },
    setGanttStatus(state, action) {
      state.ganttStatus = action.payload;
    },
    setPopStatus(state, action) {
      state.popStatus = action.payload;
    },
    setPopShowing(state, action) {
      state.popShowing = action.payload;
    },
    setChattingStatus(state, action) {
      state.chattingStatus = action.payload;
    },
    setSummaryItemStatus(state, action) {
      state.summaryItemStatus = action.payload;
    },
    setSummaryLineStatus(state, action) {
      state.summaryLineStatus = action.payload;
    },
    setSummaryResultStatus(state, action) {
      state.summaryResultStatus = action.payload;
    },
    setLineSummaryStatus(state, action) {
      state.lineSummaryStatus = action.payload;
    },
    setSimResult(state, action) {
      state.simResult = action.payload;
    },
    setPlanWeek(state, action) {
      state.planWeek = action.payload;
    },
    setSimulation1(state, action) {
      state.simulation1 = action.payload;
    },
    setSimulation2(state, action) {
      state.simulation2 = action.payload;
    },
    setWorkerStatus(state, action) {
      state.workerStatus = action.payload;
    },
    setQrData: (state, action) => {
      state.qrData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorage.fulfilled, (state, action) => {
      state.storage = action.payload;
    });
  },
});

export const {
  setUser,
  logout,
  setAssetCode,
  setGraphStatus,
  setKpiStatus,
  setMonth,
  setPopStatus,
  setPopShowing,
  setLoggingOut,
  setGanttStatus,
  setChattingStatus,
  setSummaryItemStatus,
  setSummaryLineStatus,
  setSummaryResultStatus,
  setLineSummaryStatus,
  setSimResult,
  setPlanWeek,
  setSimulation1,
  setSimulation2,
  setWorkerStatus,
  setQrData,
} = authSlice.actions;
export default authSlice.reducer;
