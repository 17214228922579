// import ReactGA from "react-ga4";
import { useEffect } from "react";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoute from "./Route/AllRoute";
import "./App.css";
import "./components/M/table.css"
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchStorage } from "./slice/authSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    injectStyle();
  }, []);
  useEffect(() => {
    dispatch(fetchStorage());
  }, []);

    // // Google Analytics 설정
    // useEffect(() => {
    //   ReactGA.initialize("G-WNCP7CDD5V");
    //   ReactGA.send("pageview");
    // }, []);

  return (
    <Router basename="/">
      <AuthProvider>
          <AllRoute />
          <ToastContainer
            autoClose={1000}
            hideProgressBar
            pauseOnHover={false}
            closeOnClick
            theme="dark"
            limit={1}
            queueMax={0}
          />
      </AuthProvider>
    </Router>
  );
}

export default App;
