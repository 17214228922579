import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  console.log("isAuthenticated : ", isAuthenticated);

    // 쿠키에서 그룹 로그인 페이지 확인
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [key, value] = cookie.split("=");
      acc[key] = value;
      return acc;
    }, {});
    const groupLoginPagePath = cookies.groupLoginPagePath || "/";

  if (!isAuthenticated) {
    return <Navigate to={groupLoginPagePath} replace />;
  }

  return children;
};

export default ProtectedRoute;