import { useNavigate, useLocation } from "react-router-dom";
import styles from "../../pages/main/main.module.css";
import { menuItems } from "../../constants/menuItem";
import { useState, useCallback } from "react";
import {
  Popper,
  MenuList,
  MenuItem,
  Grow,
  ClickAwayListener,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../../styles/colors";

const HeaderButton = styled.button`
  font-size: 20px;
  border: none;
  color: ${(props) =>
    props.$isActive ? COLORS.TEXT_DARKER : COLORS.TEXT_DARK};
  background: ${COLORS.BACKGROUND_BRIGHTER};
  font-weight: 400;
  cursor: pointer;
  font-family: Pretendard !important;
  padding: 5px 15px;
  border-radius: 8px;
  &:hover {
    border-radius: 8px;
    transition: all 0.3s;
    color: ${COLORS.TEXT_DARKER};
    font-weight: bold;
  }
`;

function Header({ group_code, project_code }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = useCallback((event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  }, []);
  const handleMenuClose = useCallback(() => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  }, []);
  const handleMainMenuClick = useCallback(
    (path) => {
      navigate(`${location.pathname}${path}`);
      handleMenuClose();
    },
    [navigate]
  );
  const handleSubMenuClick = useCallback(
    (mainPath, subPath) => {
      const pathParts = location.pathname.split("/");
      const basePath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`;
      navigate(`${basePath}${mainPath}${subPath}`);
      handleMenuClose();
    },
    [navigate, location.pathname]
  );
  const isMainMenuActive = useCallback(
    (path) => {
      return location.pathname.startsWith(path);
    },
    [location.pathname]
  );
  const isSubMenuActive = useCallback(
    (mainPath, subPath) => {
      return location.pathname === `${location.pathname}${mainPath}${subPath}`;
    },
    [location.pathname]
  );
  const handleMainPageNavigate = () => {
    navigate(`/safe/${group_code}/${project_code}`);
  };
  return (
    <>
      <header className={styles.header}>
        <div>
          <img
            style={{ width: "50%", cursor: "pointer" }}
            src="/images/generatedtext.png"
            alt="header_logo"
            onClick={handleMainPageNavigate}
          />
        </div>
        <nav className={styles.headerNav}>
          {menuItems.map((item, index) => {
            const isActive = isMainMenuActive(item.path);
            return (
              <HeaderButton
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    handleMainMenuClick(item.path);
                  }
                }}
                onMouseEnter={(e) => handleMenuOpen(e, index)}
                key={index}
                $isActive={isActive}
              >
                {item.title}
                <Popper
                  open={openMenuIndex === index}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ zIndex: "99999" }}
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper
                        elevation={3}
                        sx={{
                          minWidth: "220px",
                          borderRadius: "8px",
                          backgroundColor: COLORS.BACKGROUND,
                          border: `1px solid ${COLORS.SECONDARY}`,
                          mt: 1,
                        }}
                        onMouseLeave={handleMenuClose}
                      >
                        <ClickAwayListener onClickAway={handleMenuClose}>
                          <MenuList>
                            {item.subItems?.map((subItem, subIndex) => {
                              const isSubActive = isSubMenuActive(
                                item.path,
                                subItem.path
                              );
                              return (
                                <MenuItem
                                  key={subIndex}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSubMenuClick(item.path, subItem.path);
                                  }}
                                  sx={{
                                    fontSize: "1rem",
                                    padding: "10px 24px",
                                    color: isSubActive
                                      ? COLORS.BACKGROUND_BRIGHTER
                                      : COLORS.TEXT_DARK,
                                    backgroundColor: isSubActive
                                      ? COLORS.SECONDARY
                                      : "transparent",
                                    transition: "all 0.2s ease",
                                    zIndex: "9999",
                                    "&:hover": {
                                      backgroundColor: COLORS.BACKGROUND_DARK,
                                      color: COLORS.TEXT_BRIGHT,
                                    },
                                  }}
                                >
                                  {subItem.title}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </HeaderButton>
            );
          })}
        </nav>
      </header>
    </>
  );
}

export default Header;
