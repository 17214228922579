import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import CustomPagination from '../pagination/CustomPagination';
import { Box, Card, Button, CardHeader, CardContent, IconButton, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import NewUserDialog from '../dialogs/NewUserDialog';
// import EditUserDialog from '../dialogs/EditUserDialog';

const UsersPage = () => {
  const user = useSelector((state) => state.auth.user);
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [users, setUsers] = useState([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios.post('/backend/project/getUserList')
      .then(res => {
        setUsers(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleNewUser = () => {
    setShowNewUserDialog(true);
  };

  const handleEditUser = (user_no, event) => {
    event.stopPropagation();
    setSelectedUserId(user_no);
    setShowEditUserDialog(true);
  };

  const handleDeleteUser = (user_no, event) => {
    event.stopPropagation();
    axios.delete(`/api/users/${user_no}`)
      .then(() => {
        setUsers(users.filter(post => post.user_no !== user_no));
      })
      .catch(err => {
        console.error(err);
      });
  };

  const columns = [
    { field: 'user_no', headerName: '#', width: 70 },
    { field: 'login_id', headerName: '아이디', width: 100 },
    { field: 'user_name', headerName: '이름', width: 100 },
    { field: 'email', headerName: '이메일', width: 100 },
    { field: 'user_type', headerName: '권한', width: 100 },
    { field: 'team_name', headerName: '팀', width: 100 },
    { field: 'join_date', headerName: '입사일', width: 100 },
    {
      field: 'created_at',
      headerName: '생성일',
      width: 180,
      renderCell: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
      }
    },
    {
      flex: 0.15,
      minWidth: 120,
      sortable: false,
      field: 'actions',
      headerName: '편집',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          <IconButton onClick={(event) => handleEditUser(row.user_no, event)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={(event) => handleDeleteUser(row.user_no, event)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  return (
    <Grid container spacing={0} sx={{ height: "100%", p: 4 }}>
      <Grid item xs={12} sx={{ height: "100%" }}>
        <Card sx={{ height: "100%" }}>
          <CardHeader
            title="계정 리스트"
            action={
              <Button variant="contained" color="primary" onClick={handleNewUser}>
                신규 생성
              </Button>
            }
          />
          <CardContent sx={{ height: "calc(100% - 72px)", display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={users}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                getRowId={(row) => row.user_no}
                autoHeight={false}
                slots={{
                  pagination: CustomPagination,
                }}
                sx={{ height: "100%" }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* <NewUserDialog open={showNewUserDialog} onClose={() => setShowNewUserDialog(false)} onUserAdded={fetchUsers} />
      <EditUserDialog open={showEditUserDialog} onClose={() => setShowEditUserDialog(false)} userId={selectedUserId} onUserUpdated={fetchUsers} /> */}
    </Grid>
  );
};

export default UsersPage;