export const menuItems = [
  {
    title: "정보관리",
    subItems: [
      {
        title: "공정등록",
        subTitle:
          "위험성평가 보고서에 기록될 위험성평가 대상 공정과 공정 내용을 작성합니다.",
        path: "/register",
        description: "위험성평가 보고서내 포함될 공정 내용을 등록합니다.",
      },
      // {
      //   title: "안전보건 방침 및 목표",
      //   path: "/goal",
      //   description: "안전보건 방침 및 목표를 등록합니다.",
      //   subTitle: "안전보건 방침과 목표를 작성합니다.",
      // },
      // {
      //   title: "안전보건관리 조직도",
      //   description: "위험성평가 관리 및 승인 주체를 등록합니다.",
      //   path: "/org-chart",
      //   subTitle: "안전보건관리 조직도를 작성합니다.",
      // },
      {
        title: "설비기준정보",
        path: "/mch-info",
        description:
          "위험성평가 대상 설비(생산설비/부대설비) 등록, 설비(기계, 기구, 장비 등) 중심의 위험성평가 체계를 구축합니다.",
        subTitle: "위험성평가 대상의 기계, 기구, 설비의 정보를 작성합니다.",
      },
      {
        title: "위험발생상황 및 결과",
        path: "/cause",
        description:
          "공정별 대상설비 등록 후 설비별 위험요인 및 위험발생상황 작성을 위한 표준 위해위험요인을 등록합니다.",
        subTitle:
          "표준 유해위험요인을 선택하여 유해위험요인을 추가할 수 있도록 표준 유해위험요인을 사전 등록합니다.",
      },
      {
        title: "위험성 추정 기준표",
        path: "/calculation",
        description:
          "위험성 수준 평가 및 평가결과의 위험허용 여부를 판단하기 위한 위험성 추정 기준표를 등록합니다.",
        subTitle:
          "위험성 수준 평가결과의 위험허용 여부를 판단하기 위한 위험성 추정 기준표(위험성 평가 산출)를 등록합니다. ",
      },
      {
        title: "안전보건",
        path: "/law",
        description:
          "안전보건 방침 및 목표, 위험성평가 관리 및 승인 주체, 위험성 감소대책 실행 후 안전보건 법규에 위배되지 않는 지 확인하기 위한 등록",
        subTitle:
          "안전보건 방침 및 목표, 위험성평가 관리 및 승인 주체, 위험성 감소대책 실행 후 안전보건 법규에 위배되지 않는 지 확인하기 위한 등록",
      },
      {
        title: "비상사태 시나리오",
        path: "/scenario",
        description:
          "예상치 못한 긴급 상황에서 인명 및 재산 피해를 최소화하기 위하여 신속하고 효과적으로 대응하기 위한 훈련 계획을 확인합니다.",
        subTitle:
          "비상 상황에서 인명 및 재산 피해를 최소화하기 위하여 신속하고 효과적으로 대응하기 위한 훈련 계획을 등록합니다.",
      },
    ],
    path: "/standard",
  },
  {
    title: "등록관리",
    subItems: [
      {
        title: "위험성평가 대상 설비 등록",
        path: "/target-register",
        description: "위험성평가 대상 설비를 공정과 연계하여 등록",
        subTitle: "위험성평가 대상 설비를 공정과 연계하여 등록합니다.",
      },
      {
        title: "유해위험요인 등록",
        path: "/risk-register",
        description:
          "위험성평가 대상 설비에 대해 표준 유해위험요인 DB를 활용하여 유해위험요인 등록",
        subTitle:
          "위험성평가 대상 설비에 대해 표준 유해위험요인 DB를 활용하여 유해위험요인을 등록합니다.",
      },
      {
        title: "유해위험요인 등록현황",
        path: "/status",
        description: "위험성평가 대상 설비에 대해 등록된 유해위험요인 조회",
        subTitle: "위험성평가 대상 설비에 대해 유해위험요인을 조회합니다.",
      },
    ],
    path: "/risk-register",
  },
  {
    title: "평가관리",
    path: "/assessment",
    subItems: [
      {
        title: "위험성 수준 평가",
        path: "/level",
        subTitle:
          "위험성평가 대상 설비의 유해위험요인에 대해 위험성을 추정합니다.",
        description: "위험성평가 대상 설비의 유해위험요인에 대해 위험성 추정",
      },
      {
        title: "위험성 추정 결과",
        path: "/calculation",
        description:
          "위험성평가 대상 설비의 유해위험요인에 대해 위험성 추정결과를 최종 확인",
        subTitle:
          "위험성평가 대상 설비의 유해위험요인에 대한 위험성 추정 결과를 최종 확인합니다.",
      },
      {
        title: "위험성 평가 현황",
        path: "/status",
        description: "위험성평가 대상 설비의 위험성 평가 이력 조회",
        subTitle: "위험성평가 대상 설비의 위험성 평가 이력을 조회합니다.",
      },
    ],
  },
  {
    title: "대책관리",
    path: "/risk-control",
    subItems: [
      {
        title: "위험성 감소대책 등록",
        path: "/risk-measures",
        description:
          "위험성평가 결과 위험성 허용불가의 유해위험요인에 대해 개선대책 등록",
        subTitle:
          "위험성평가 결과 위험성 허용불가의 유해위험요인에 대해 개선대책을 등록합니다.",
      },
      {
        title: "위험성 감소대책 일정 관리",
        path: "/risk-measures-schedule",
        description: "유해위험요인의 개선대책에 대한 세부일정 등록",
        subTitle: "유해위험요인의 개선대책에 대한 세부일정을 등록합니다.",
      },
      {
        title: "개선실행 결과관리",
        path: "/risk-management",
        description: "유해위험요인의 개선대책에 대한 실행완료일정 등록",
        subTitle: "유해위험요인의 개선대책에 대한 실행일정을 등록합니다.",
      },
    ],
  },
  {
    title: "모니터링",
    path: "/monitoring",
    subItems: [
      {
        title: "설비 위험성 평가 결과",
        path: "/mch-risk-result",
        description: "위험성 평가 결과에 대해 지표화 및 그래프화",
        subTitle: "위험성 평가 결과에 대해 지표화 및 그래프화 합니다.",
      },
      {
        title: "대책수립 결과",
        path: "/conclusion",
        description:
          "위험성 평가에 따른 감소대책실행 결과에 대해 지표화 및 그래프화",
        subTitle:
          "위험성 평가에 따른 감소대책실행 결과에 대해 지표화 및 그래프화 합니다.",
      },
      {
        title: "위험성평가 보고서",
        path: "/report",
        description:
          "매월 정기적으로 실시되는 위험성평가 결과에 대한 보고서 작성",
        subTitle:
          "매월 정기적으로 실시되는 위험성평가 결과에 대한 보고서를 작성합니다.",
      },
    ],
  },
];
