import { useLocation } from "react-router-dom";
import { menuItems } from "../constants/menuItem";

export const useGetTitle = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const defaultResult = {
    title: "",
    path: "/",
    description: "",
    subTitle: "",
  };
  if (pathSegments.length < 4) return defaultResult;
  const targetPath1 = pathSegments[pathSegments.length - 2];
  const targetPath2 = pathSegments[pathSegments.length - 1];
  const firstLevelItem = menuItems.find((item) => {
    const itemPath = item.path.split("/").filter(Boolean)[0];
    return itemPath === targetPath1;
  });
  if (!firstLevelItem?.subItems) {
    return firstLevelItem
      ? {
          title: firstLevelItem.title,
          path: firstLevelItem.path,
        }
      : defaultResult;
  }
  const secondLevelItem = firstLevelItem.subItems.find((subItem) => {
    const subItemPath = subItem.path.split("/").filter(Boolean);
    return subItemPath[subItemPath.length - 1] === targetPath2;
  });
  return secondLevelItem
    ? {
        title: secondLevelItem.title,
        path: secondLevelItem.path,
        description: secondLevelItem.description,
        subTitle: secondLevelItem.subTitle,
      }
    : defaultResult;
};
