import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

const DatagridButtonGroup = ({ handleAddRow, handleDeleteRow, handleSaveRow, selectionModel }) => {
  return (
    <>
      <Divider sx={{ m: '0 !important' }} />
      <Box
        sx={{
          py: 2,
          px: 6,
          gap: '5px',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddRow}>
          추가
        </Button>
        <Button variant="contained" color="success" startIcon={<SaveIcon />} onClick={handleSaveRow} disabled={selectionModel.length === 0}>
          저장
        </Button>
        <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={handleDeleteRow} disabled={selectionModel.length === 0}>
          삭제
        </Button>
      </Box>
      <Divider sx={{ m: '0 !important' }} />
    </>
  );
};

export default DatagridButtonGroup;