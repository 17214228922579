import { useState } from "react";
import { useGetTitle } from "../../../hooks/useGetTitle";
import { useParams } from "react-router-dom";
import Header from "../../../components/Layout/Header";
import TableComponent from "../../../components/common/SingleTableComponent";
import {
  standardLawColumns,
  standardGoalColumns,
  standardCourseColumns,
  standardOrgColumns,
} from "../../../constants/columns";
import { useGetTableData } from "../../../components/Table/hooks";
import ReusableTable from "../../../components/Table/ReusableTable";
import styles from "../../main/main.module.css";

function StandardLaw() {
  const { group_code, project_code } = useParams();
  console.log(group_code, project_code);
  const titleInfo = useGetTitle();
  const { title, subTitle } = titleInfo;
  const [updateStatus, setUpdateStatus] = useState(false);
  const displayModeObject = {
    createDisplayMode: "modal",
    editDisplayMode: "modal",
  };
  const buttons = [
    {
      text: "추가",
      variant: "add",
      type: "create",
    },
    {
      text: "삭제",
      variant: "delete",
      type: "delete",
    },
  ];
  return (
    <>
      <Header group_code={group_code} project_code={project_code} />
      <TableComponent title={title} subTitle={subTitle}>
        <ReusableTable
          columns={standardLawColumns}
          group_code={group_code}
          project_code={project_code}
          baseUrl={[
            "/getSafeLaw",
            "/postSafeLaw",
            "/updateSafeLaw",
            "/deleteSafeLaw",
          ]}
          title="안전보건 법규"
          displayConfigObj={displayModeObject}
          boxWidth="82vw"
          buttons={buttons}
          exlucdColumns={["actions"]}
          isRowEdit={true}
          deleteParam="law_id"
        />
        <div style={{ display: "flex", gap: "1rem" }}>
          <ReusableTable
            columns={standardGoalColumns}
            group_code={group_code}
            project_code={project_code}
            baseUrl={["/getSafeGoal"]}
            title="안전보건경영 목표"
            displayConfigObj={displayModeObject}
            boxWidth="20vw"
            buttons={buttons}
            exlucdColumns={["goal_id"]}
            deleteParam="goal_id"
          />
          <ReusableTable
            columns={standardCourseColumns}
            group_code={group_code}
            project_code={project_code}
            baseUrl={["/getSafeCourse"]}
            title="안전보건경영 방침"
            displayConfigObj={displayModeObject}
            boxWidth="24vw"
            buttons={buttons}
            exlucdColumns={["course_id"]}
          />
          <ReusableTable
            columns={standardOrgColumns}
            group_code={group_code}
            project_code={project_code}
            baseUrl={["/getSafeOrg"]}
            title="안전보건경영 조직도"
            displayConfigObj={displayModeObject}
            boxWidth="38vw"
            buttons={buttons}
            exlucdColumns={["org_id", "actions"]}
            isRowEdit={true}
          />
        </div>
      </TableComponent>
    </>
  );
}

export default StandardLaw;
