import { useGetTitle } from "../../../hooks/useGetTitle";
import TableComponent from "../../../components/common/SingleTableComponent";
import RedCheck from "../../../components/icons/RedCheck";
import FrequencyTable from "../../../components/Table/FrequencyTable";
import ImportancyTable from "../../../components/Table/ImportancyTable";
import { useParams } from "react-router-dom";
import { useCalculationData } from "../../../hooks/useCalculationData";
import CalculationTable from "../../../components/Table/CalculationTable";
import Header from "../../../components/Layout/Header";

// 위험성 평가 기준표
function StandardCalculation() {
  const titleInfo = useGetTitle();
  const { group_code, project_code } = useParams();
  const { title, subTitle } = titleInfo;
  const { tableData } = useCalculationData(group_code, project_code);
  const { frequencyData, importancyData, standardData } = tableData;
  return (
    <>
      <Header group_code={group_code} project_code={project_code} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TableComponent
          title={title}
          subTitle={subTitle}
          tableShow={false}
          isCalTable={true}
        ></TableComponent>
        <div
          style={{
            width: "85vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CalculationTable
            frequencyData={frequencyData}
            importancyData={importancyData}
            standardData={standardData}
          />
        </div>
      </div>
    </>
  );
}

export default StandardCalculation;
