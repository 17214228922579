// ** Overrides Imports
import MuiAlerts from './alerts'
import MuiButton from "./button";
import MuiCard from "./card";
import MuiChip from './chip'
import MuiCheckbox from "./checkbox";
import MuiDataGrid from './dataGrid'
import MuiDialog from "./dialog";
import MuiDivider from "./divider";
import MuiIconButton from "./icon-button";
import MuiInput from "./input";
import MuiPaper from './paper'
import MuiSwitches from './switches'
import MuiTabs from './tabs'
import MuiTypography from "./typography";

const Overrides = () => {
  const alerts = MuiAlerts()
  const chip = MuiChip()
  const button = MuiButton();
  const cards = MuiCard();
  const checkbox = MuiCheckbox();
  const dataGrid = MuiDataGrid()
  const dialog = MuiDialog();
  const divider = MuiDivider();
  const input = MuiInput();
  const switches = MuiSwitches()
  const tabs = MuiTabs()

  return Object.assign(
    alerts,
    button,
    cards,
    checkbox,
    chip,
    dataGrid,
    dialog,
    divider,
    input,
    switches,
    MuiIconButton,
    MuiPaper,
    MuiTypography,
    tabs
  );
};

export default Overrides;
