function RedCheck() {
  return (
    <svg
      height="14"
      width="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#ff1100">
        <path
          d="M5.27 11.7h-0.01a0.59 0.59 0 0 1-0.45-0.22l-3.12-3.9a0.58 0.58 0 0 1 0.91-0.73l2.67 3.33 6.17-7.62a0.58 0.58 0 0 1 0.91 0.73l-6.63 8.19a0.58 0.58 0 0 1-0.45 0.22z"
          fill="#ff1100"
        />
      </g>
    </svg>
  );
}

export default RedCheck;
