import React, { useState } from "react";
import { axiosPrivate } from "../../../api/axios";
import CustomTextField from "../../CustomTextField";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, Grid, FormHelperText } from "@mui/material";

const NewProjectDialog = ({ open, onClose, onProjectAdded }) => {
  const [formValues, setFormValues] = useState({
    project_name: "",
    project_code: "",
  });
  const [errors, setErrors] = useState({
    project_name: "",
    project_code: "",
  });

  const validateField = (field, value) => {
    let errorMessage = "";

    if (field === "project_code") {
      const regex = /^[a-zA-Z0-9]{2,6}$/;
      if (!regex.test(value)) {
        errorMessage = "공백없이 영문 알파벳 및 숫자 2~6 글자로 입력해주세요.";
      }
    }

    if (field === "project_name" && value.trim() === "") {
      errorMessage = "프로젝트 이름을 입력해주세요.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));

    return errorMessage === "";
  };

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    validateField(field, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { project_name, project_code } = formValues;

    // 모든 필드를 검증
    const isNameValid = validateField("project_name", project_name);
    const isCodeValid = validateField("project_code", project_code);

    if (!isNameValid || !isCodeValid) {
      return;
    }

    axiosPrivate
      .post("/project/newProject", { project_name, project_code })
      .then(() => {
        onProjectAdded();
        setFormValues({
          project_name: "",
          project_code: "",
        });
        onClose();
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            project_code: "이미 존재하는 프로젝트 코드입니다.",
          }));
        } else {
          console.error(err);
          alert("프로젝트 생성 오류");
        }
      });
  };

  return (
    <Dialog fullWidth open={open} maxWidth="sm" onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogContent
          sx={{
            pb: (theme) => `${theme.spacing(8)} !important`,
            px: (theme) => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
            pt: (theme) => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`],
          }}
        >
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Typography variant="h3" sx={{ mb: 3 }}>
              신규 프로젝트
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>프로젝트 정보 입력</Typography>
          </Box>

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                name="project_name"
                value={formValues.project_name}
                autoComplete="off"
                label="프로젝트 이름"
                onChange={handleChange("project_name")}
                placeholder="프로젝트 이름"
                required
                error={Boolean(errors.project_name)}
              />
              {errors.project_name && <FormHelperText error>{errors.project_name}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                name="project_code"
                value={formValues.project_code}
                autoComplete="off"
                label="프로젝트 식별 코드"
                placeholder="프로젝트 식별 코드"
                helperText="공백없이 영문 알파벳 및 숫자 2~6 글자"
                onChange={handleChange("project_code")}
                required
                error={Boolean(errors.project_code)}
              />
              {errors.project_code && <FormHelperText error>{errors.project_code}</FormHelperText>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            px: (theme) => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
            pb: (theme) => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`],
          }}
        >
          <Button variant="contained" sx={{ mr: 1 }} type="submit">
            생성
          </Button>
          <Button variant="tonal" color="secondary" onClick={onClose}>
            취소
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewProjectDialog;