import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  LocalizationProvider,
  DatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import { MRT_EditActionButtons as ActioButtons } from "material-react-table";
import { COLORS } from "../../styles/colors";

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  boxShadow: 24,
  padding: theme.spacing(4),
}));

const commonSx = {
  marginBottom: 3,
  borderRadius: "4px",
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
  },
  "& .MuiInputLabel-root": {
    marginTop: "0px",
  },
  "& .MuiInputLabel-shrink": {
    marginTop: "0px",
  },
};

function EditTableModal({
  table,
  row,
  exlucdColumns = [],
  selectData = {},
  isTwoColumns = false,
}) {
  const [newRow, setNewRow] = useState(() => {
    // 초기값 설정 시 date/datetime 필드 처리
    const initialData = { ...row.original };
    table.options.columns.forEach((column) => {
      if (column.type === "date" || column.type === "datetime") {
        initialData[column.accessorKey] = initialData[column.accessorKey]
          ? dayjs(initialData[column.accessorKey])
          : null;
      }
    });
    return initialData;
  });
  console.log("newRow : ", newRow);
  const handleInputChange = (field, value) => {
    setNewRow((prev) => ({ ...prev, [field]: value }));
    const column = table.options.columns.find(
      (col) => col.accessorKey === field
    );
    const updateValue =
      column?.type === "date" || column?.type === "datetime"
        ? value?.format("YYYY-MM-DD HH:mm:ss")
        : value;
    row._valuesCache[field] = updateValue;
    row.original[field] = updateValue;
  };

  const renderField = (column) => {
    if (exlucdColumns.includes(column.accessorKey)) return null;
    if (!column.accessorKey) return null;

    const currentValue = newRow[column.accessorKey];

    switch (column.type) {
      case "select":
        return (
          <FormControl fullWidth sx={commonSx} key={column.accessorKey}>
            <InputLabel>{column.header}</InputLabel>
            <Select
              value={currentValue || ""}
              label={column.header}
              onChange={(e) =>
                handleInputChange(column.accessorKey, e.target.value)
              }
            >
              {column.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "date":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="ko"
            key={column.accessorKey}
          >
            <DatePicker
              label={column.header}
              value={currentValue}
              onChange={(newValue) =>
                handleInputChange(column.accessorKey, newValue)
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: commonSx,
                },
              }}
            />
          </LocalizationProvider>
        );
      case "datetime":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="ko"
            key={column.accessorKey}
          >
            <DateTimePicker
              label={column.header}
              value={currentValue}
              onChange={(newValue) =>
                handleInputChange(column.accessorKey, newValue)
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: commonSx,
                },
              }}
            />
          </LocalizationProvider>
        );
      default:
        return (
          <TextField
            key={column.accessorKey}
            label={column.header}
            fullWidth
            value={currentValue || ""}
            onChange={(e) =>
              handleInputChange(column.accessorKey, e.target.value)
            }
            sx={commonSx}
          />
        );
    }
  };
  const renderColumns = () => {
    const validColumns = table.options.columns.filter(
      (column) =>
        column.accessorKey && !exlucdColumns.includes(column.accessorKey)
    );
    if (!isTwoColumns) {
      return validColumns.map((column) => renderField(column));
    }
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {validColumns.map((column, index) => (
          <Box
            key={column.accessorKey}
            sx={{
              width: "calc(50% - 8px)",
              ...(index === validColumns.length - 1 &&
              validColumns.length % 2 === 1
                ? { width: "calc(50% - 8px)" }
                : {}),
            }}
          >
            {renderField(column)}
          </Box>
        ))}
      </Box>
    );
  };
  return (
    <Modal open={true}>
      <ModalContainer sx={{ background: COLORS.BACKGROUND_BRIGHTER }}>
        <Typography variant="h6" component="h2" mb={3}>
          수정하기
        </Typography>
        {renderColumns()}
        <DialogActions>
          <ActioButtons variant="text" table={table} row={row} />
        </DialogActions>
      </ModalContainer>
    </Modal>
  );
}

export default EditTableModal;
