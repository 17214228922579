import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RedCheck from "../icons/RedCheck";

const CalculationTable = ({ frequencyData, importancyData, standardData }) => {
  // 가능성 레벨에 대한 한글 매핑
  const frequencyMapping = {
    5: "최상",
    4: "상",
    3: "중",
    2: "하",
    1: "최하",
  };

  // 중대성 레벨에 대한 한글 매핑
  const importancyMapping = {
    4: "최대",
    3: "대",
    2: "중",
    1: "소",
  };

  // 위험도 레벨에 따른 색상 및 텍스트 매핑
  const riskLevelMapping = {
    "16-20": { text: "매우높음", color: "#FF4444", textColor: "white" },
    "12-15": { text: "높음", color: "#FFB800", textColor: "black" },
    "8-11": { text: "약간높음", color: "#FFD5B8", textColor: "black" },
    "4-7": { text: "낮음", color: "#D5FFD5", textColor: "black" },
    "1-3": { text: "매우낮음", color: "#B8FFB8", textColor: "black" },
  };

  // 위험도 계산 함수
  const calculateRiskLevel = (freqLevel, impLevel) => {
    const riskValue = freqLevel * impLevel;
    return {
      value: riskValue,
      ...getRiskLevelStyle(riskValue),
    };
  };

  // 위험도 값에 따른 스타일 반환
  const getRiskLevelStyle = (value) => {
    if (value >= 16) return riskLevelMapping["16-20"];
    if (value >= 12) return riskLevelMapping["12-15"];
    if (value >= 8) return riskLevelMapping["8-11"];
    if (value >= 4) return riskLevelMapping["4-7"];
    return riskLevelMapping["1-3"];
  };

  return (
    <TableContainer
      sx={{
        "& *": {
          fontFamily: "Pretendard !important",
        },
      }}
    >
      {/* 5x4 위험성 추정 기준 */}
      <Typography
        variant="subtitle1"
        sx={{
          mb: 2,
          fontWeight: "bold",
          color: "rgb(37, 99, 235)",
          fontSize: "1.125rem",
          lineHeight: "1.5rem",
        }}
      >
        5×4 위험성 추정 기준
      </Typography>
      <Table
        sx={{
          border: "2px solid #e5e5e5",
          // tableLayout: "fixed",
          // width: "100%",
          // "& th:first-of-type, & td:first-of-type": {
          //   width: "150px",
          // },
          // "& th:nth-of-type(2), & td:nth-of-type(2)": {
          //   width: "150px",
          // },
          // "& th:not(:first-of-type):not(:nth-of-type(2)), & td:not(:first-of-type):not(:nth-of-type(2))":
          //   {
          //     width: "calc((100% - 300px) / 4)",
          //   },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} rowSpan={2}></TableCell>
            <TableCell
              colSpan={4}
              align="center"
              sx={{
                background: "#DBEAFE",
                fontWeight: "600",
                padding: "8px 16px",
                fontSize: "1.12rem",
              }}
            >
              중대성(강도)
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              "& td": {
                borderRight: "1px solid #e5e5e5",
              },
              "& td:last-child": {
                borderRight: "none",
              },
            }}
          >
            {Object.entries(importancyMapping)
              .reverse()
              ?.map(([level, text]) => (
                <TableCell
                  key={level}
                  align="center"
                  sx={{
                    background: "#FCFCFA",
                    fontWeight: "500",
                    padding: "8px 16px",
                  }}
                >
                  {text}({level})
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(frequencyMapping)
            .reverse()
            ?.map(([freqLevel, freqText]) => (
              <TableRow key={freqLevel}>
                {freqLevel === "5" && (
                  <TableCell
                    rowSpan={5}
                    sx={{
                      width: "100px",
                      background: "#DBEAFE",
                      fontWeight: "600",
                      fontSize: "1.12rem",
                      "& td": {
                        borderRight: "1px solid #e5e5e5",
                      },
                      "& td:last-child": {
                        borderRight: "none",
                      },
                    }}
                    align="center"
                  >
                    가능성
                    <br />
                    (빈도)
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    width: "100px",
                    background: "#FCFCFA",
                    fontWeight: "500",
                    padding: "8px 16px",
                  }}
                  align="center"
                >
                  {freqText}({freqLevel})
                </TableCell>
                {Object.keys(importancyMapping)
                  .reverse()
                  ?.map((impLevel) => {
                    const risk = calculateRiskLevel(
                      Number(freqLevel),
                      Number(impLevel)
                    );
                    return (
                      <TableCell
                        key={impLevel}
                        align="center"
                        sx={{
                          bgcolor: risk.color,
                          color: risk.textColor,
                          padding: "8px 16px",
                          "& td": {
                            borderRight: "1px solid #e5e5e5",
                          },
                          "& td:last-child": {
                            borderRight: "none",
                          },
                        }}
                      >
                        {`${risk.text}(${risk.value})`}
                      </TableCell>
                    );
                  })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {/* 판단 기준 및 허용 기준 테이블들 */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 2fr" },
          gap: 2,
          mt: 2,
        }}
      >
        {/* 가능성 수준 판단 기준 */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 2,
              fontWeight: "bold",
              color: "rgb(37, 99, 235)",
              fontSize: "1.125rem",
              lineHeight: "1.5rem",
            }}
          >
            위험성 수준 판단 기준
          </Typography>
          <Table sx={{ border: "2px solid #e5e5e5" }} size="small">
            <TableHead>
              <TableRow
                sx={{
                  fontWeight: "500",
                  padding: "8px 16px",
                  "& td": {
                    borderRight: "1px solid #e5e5e5",
                  },
                  "& td:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                <TableCell
                  colSpan={2}
                  align="center"
                  sx={{
                    background: "#DBEAFE",
                    fontSize: "1rem",
                    padding: "8px 16px",
                  }}
                >
                  가능성(빈도)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(frequencyMapping)
                .reverse()
                ?.map(([level, text]) => (
                  <TableRow
                    key={level}
                    sx={{
                      fontSize: "1rem",
                      padding: "8px 16px",
                      "& td": {
                        borderRight: "1px solid #e5e5e5",
                      },
                      "& td:last-child": {
                        borderRight: "none",
                      },
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{ whiteSpace: "nowrap", padding: "8px 16px" }}
                    >
                      {text}({level})
                    </TableCell>
                    <TableCell align="center" sx={{ padding: "8px 16px" }}>
                      {
                        frequencyData?.find(
                          (f) => f.frequency_level === Number(level)
                        ).frequency_description
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>

        {/* 중대성 판단 기준 */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 2,
              fontWeight: "bold",
              color: "rgb(37, 99, 235)",
              fontSize: "1.125rem",
              lineHeight: "1.5rem",
            }}
          >
            중대성 판단 기준
          </Typography>
          <Table sx={{ border: "2px solid #e5e5e5" }} size="small">
            <TableHead>
              <TableRow
                sx={{
                  fontWeight: "500",
                  padding: "8px 16px",
                  "& td": {
                    borderRight: "1px solid #e5e5e5",
                  },
                  "& td:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                <TableCell
                  colSpan={2}
                  align="center"
                  sx={{
                    background: "#DBEAFE",
                    fontSize: "1rem",
                    padding: "8px 16px",
                  }}
                >
                  중대성(강도)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(importancyMapping)
                .reverse()
                ?.map(([level, text]) => (
                  <TableRow
                    sx={{
                      fontSize: "1rem",
                      padding: "8px 16px",
                      "& td": {
                        borderRight: "1px solid #e5e5e5",
                      },
                      "& td:last-child": {
                        borderRight: "none",
                      },
                    }}
                    key={level}
                  >
                    <TableCell
                      align="center"
                      sx={{ whiteSpace: "nowrap", padding: "8px 16px" }}
                    >
                      {text}({level})
                    </TableCell>
                    <TableCell align="center" sx={{ padding: "8px 16px" }}>
                      {
                        importancyData?.find(
                          (i) => i.importancy_level === Number(level)
                        ).importancy_description
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>

        {/* 위험성 허용 기준 */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 2,
              fontWeight: "bold",
              color: "rgb(37, 99, 235)",
              fontSize: "1.125rem",
              lineHeight: "1.5rem",
            }}
          >
            위험성 허용 기준
          </Typography>
          <Table sx={{ border: "2px solid #e5e5e5" }} size="small">
            <TableHead>
              <TableRow
                sx={{
                  fontWeight: "500",
                  padding: "8px 16px",
                  "& td": {
                    borderRight: "1px solid #e5e5e5",
                  },
                  "& td:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                <TableCell
                  align="center"
                  width="20%"
                  sx={{
                    background: "#DBEAFE",
                    fontSize: "1rem",
                    padding: "8px 16px",
                  }}
                >
                  위험성 수준
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    background: "#DBEAFE",
                    fontSize: "1rem",
                    padding: "8px 16px",
                  }}
                >
                  관리기준
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {standardData?.map((standard, index) => {
                const getBgColor = (min, max) => {
                  if (min >= 16) return "#FF4444";
                  if (min >= 15) return "#EAB308";
                  if (min >= 8) return "#FDE047";
                  if (min >= 4) return "#FFFFE0";
                  return "#B8FFB8";
                };
                const bgColor = getBgColor(
                  standard.risk_level_min,
                  standard.risk_level_max
                );
                return (
                  <TableRow
                    sx={{
                      fontSize: "1rem",
                      "& td": {
                        borderRight: "1px solid #e5e5e5",
                      },
                      "& td:last-child": {
                        borderRight: "none",
                      },
                    }}
                    key={index}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        bgcolor: bgColor,
                        padding: "8px 16px",
                      }}
                    >
                      {standard.risk_level_min === standard.risk_level_max
                        ? standard.risk_level_min
                        : `${standard.risk_level_min} ~ ${standard.risk_level_max}`}
                    </TableCell>
                    <TableCell
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    >
                      {standard.requires_improve === 1 && <RedCheck />}{" "}
                      {standard.manage_criteria}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </TableContainer>
  );
};

export default CalculationTable;
