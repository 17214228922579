export const ganttMakingAlertText =
  "현재 설정한 마스터 데이터를 기반으로 생산계획을 생산하시겠습니까?";
export const orderCreateErrMsg =
  "생산계획을 추가하는데 오류가 발생했습니다. 관리자에게 문의 바랍니다.";
export const notMatchedItem =
  "해당 생산계획과 일치하는 아이템이 없습니다. 관리자에게 문의 바랍니다.";
export const notMatchedProc =
  "해당 제품에 공정이 설정되어 있지 않습니다. 마스터 데이터 관리 페이지로 이동하여 공정을 추가해주시기 바랍니다.";
export const notmatchedMch =
  "해당 제품 생산에 사용하는 장비가 설정되어 있지 않습니다. 마스터 데이터 관리 페이지로 이동하여 장비를 추가해주시기 바랍니다.";
export const completeWarning = "생산계획을 완료처리 하시겠습니까?";
export const successComplete = "정상적으로 완료처리 되었습니다.";
export const successReset = "정상적으로 초기화 되었습니다.";
export const successCreateData = "생산계획을 성공적으로 생성하였습니다.";
export const completeConfirm = "해당 생산계획을 생산완료처리 하시겠습니까?";
export const deleteError =
  "삭제 중 오류가 발생했습니다. 관리자에게 문의 바랍니다.";
export const completeAdd = "추가가 완료되었습니다.";
export const deleteSelect = "삭제할 항목을 선택해주세요.";
export const deleteConfirm = `선택한 생산계획을 삭제하시겠습니까?
해당 작업은 취소할 수 없습니다.`;
export const deleteComplete = "선택한 생산계획을 삭제했습니다.";
export const deleteSuccess = "성공적으로 삭제하였습니다.";
export const editComplete = "수정이 완료되었습니다.";
export const editError =
  "수정 중 오류가 발생했습니다. 관리자에게 문의 바랍니다.";
export const createValidNotPass = "빈칸을 전부 채워주세요.";
export const resetConfirm = "해당 생산계획을 초기 시점으로 되돌리시겠습니까?";
export const notAllowedEmpty = "빈칸을 전부 채워주세요.";
export const errorCreate = "생성에 실패했습니다. 관리자에게 문의 바랍니다.";
export const firstLotNotExisted = "시작 LOT가 존재하지 않습니다.";
export const errorQty =
  "수량이 0으로 설정되어 있어 생산 계획을 생성할 수 없습니다.";
export const notexistLot =
  "LOT가 설정되어 있지 않습니다. 설정 후 다시 시도 바랍니다.";
export const goToGanttPage = "간트 차트 페이지로 이동하시겠습니까?";
export const currentDeveloped = "현재 개발중인 기능입니다.";
export const rollbackComplete =
  "완료 처리한 생산계획을 초기 상태로 되돌리시겠습니까?";
export const resetComplete = "되돌리기가 완료되었습니다.";
export const resetError =
  "되돌리기 도중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.";
export const localizationTextObject = {
  edit: "수정",
  save: "저장",
  cancel: "취소",
  selectedCountOfRowCountRowsSelected:
    "총 {rowCount} 계획 중 {selectedCount}개의 계획이 선택되었습니다.",
  rowsPerPage: "한 페이지에 표시할 계획 갯수",
  goToNextPage: "다음 페이지로 이동",
  goToPreviousPage: "이전 페이지로 이동",
  clearSelection: "선택 전체 해제",
  actions: " ",
  // of : "중의"
  noRecordsToDisplay: `표시할 생산계획이 없습니다. 위의 "추가" 버튼을 눌러 생산계획을 추가해 주세요.`,
};
export const localizationTextObjectForInformation = {
  edit: "수정",
  save: "저장",
  cancel: "취소",
  rowsPerPage: "한 페이지에 표시할 항목 갯수",
  goToNextPage: "다음 페이지로 이동",
  goToPreviousPage: "이전 페이지로 이동",
  clearSelection: "선택 전체 해제",
  actions: " ",
  // of : "중의"
  noRecordsToDisplay: `Item이 선택되지 않았거나 
    해당 영역의 데이터가 없습니다.`,
  move: "이동",
};
export const localizationTextObjectForInformationForMachine = {
  edit: "수정",
  save: "저장",
  cancel: "취소",
  rowsPerPage: "한 페이지에 표시할 항목 갯수",
  goToNextPage: "다음 페이지로 이동",
  goToPreviousPage: "이전 페이지로 이동",
  clearSelection: "선택 전체 해제",
  actions: " ",
  // of : "중의"
  noRecordsToDisplay: `공정이 선택되지 않았거나 
    해당 영역의 데이터가 없습니다.`,
  move: "이동",
};
export const successColorSelect = "색상이 선택되었습니다.";
export const itemClickFirst = "아이템을 먼저 선택 한 후 시도해 주세요.";
export const procClickFirst = "공정을 먼저 선택 한 후 시도해 주세요.";
