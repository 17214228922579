import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { axiosPrivate } from "../../../api/axios";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import { Icon } from "../../icons";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import NewProjectDialog from "../dialogs/NewProjectDialog";
import ConfirmDialog from "../../CommonDialog/ConfirmDialog";

const StatusMasterPage = () => {
  const user = useSelector((state) => state.auth.user);
  const [groupedProjects, setGroupedProjects] = useState({});
  const [expandedGroups, setExpandedGroups] = useState({});
  const [isAdmin, setIsAdmin] = useState(false); // Admin 여부
  const [isFormOpen, setIsFormOpen] = useState(false); // 신규 프로젝트 폼 상태
  const [deleteProjectId, setDeleteProjectId] = useState(null); // 삭제할 프로젝트 ID
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // 삭제 확인 다이얼로그
  useEffect(() => {
    fetchProjectList();
  }, []);

  const fetchProjectList = () => {
    axiosPrivate
      .post("/project/getProjectList")
      .then((res) => {
        const { isAdmin: adminStatus, projects } = res.data;

        // Admin 여부 설정
        setIsAdmin(adminStatus);

        // 그룹별로 프로젝트를 분류
        const grouped = projects.reduce((acc, project) => {
          const groupName = project.group_name;
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push(project);
          return acc;
        }, {});
        setGroupedProjects(grouped);

        // 초기 확장 상태를 모두 열린 상태로 설정
        const initialExpandedState = Object.keys(grouped).reduce(
          (acc, groupName) => {
            acc[groupName] = true;
            return acc;
          },
          {}
        );
        setExpandedGroups(initialExpandedState);
      })
      .catch((err) => {
        console.error("프로젝트 리스트 가져오기 실패:", err);
      });
  };

  const handleToggleGroup = (groupName) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const handleEditProject = (groupCode, projectCode) => {
    const url = `${window.location.origin}/edit/${groupCode}/${projectCode}`;
    window.open(url, "_blank");
  };

  const handleViewProject = (groupCode, projectCode) => {
    const url = `${window.location.origin}/insightwin/${groupCode}/${projectCode}`;
    window.open(url, "_blank");
  };

  const handleSafeProject = (groupCode, projectCode) => {
    const url = `${window.location.origin}/safe/${groupCode}/${projectCode}`;
    window.open(url, "_blank");
  };

  const handleDeleteProject = async () => {
    if (!deleteProjectId) return;

    try {
      // 프로젝트 삭제 API 호출
      await axiosPrivate.delete(`/project/delete/${deleteProjectId}`);
      setDeleteDialogOpen(false); // 다이얼로그 닫기
      setDeleteProjectId(null); // 삭제 대상 ID 초기화
      fetchProjectList(); // 리스트 새로고침
    } catch (err) {
      console.error("프로젝트 삭제 실패:", err);
      alert("프로젝트 삭제 중 오류가 발생했습니다.");
    }
  };
  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleProjectAdded = () => {
    // 프로젝트 추가 후 리스트 새로고침
    fetchProjectList();
    setIsFormOpen(false);
  };
  const handleDeleteConfirm = (projectId) => {
    setDeleteProjectId(projectId); // 삭제 대상 프로젝트 ID 설정
    setDeleteDialogOpen(true); // 다이얼로그 열기
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <Grid container spacing={0} sx={{ height: "100%", p: 4 }}>
      <Grid item xs={12} sx={{ height: "100%" }}>
        <Card sx={{ height: "100%" }}>
          <CardHeader
            title="프로젝트 리스트"
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenForm}
              >
                신규 프로젝트 생성
              </Button>
            }
          />
          <CardContent sx={{ height: "calc(100% - 72px)", overflow: "auto" }}>
            <List>
              {Object.keys(groupedProjects).map((groupName) => (
                <React.Fragment key={groupName}>
                  <ListItemButton onClick={() => handleToggleGroup(groupName)}>
                    <ListItemText
                      primary={groupName}
                      primaryTypographyProps={{
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                      }} // 글자 크기 조정
                    />
                    {expandedGroups[groupName] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={expandedGroups[groupName]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      sx={{ mb: 2 }} // 그룹 간 간격 추가
                    >
                      {groupedProjects[groupName].map((project) => (
                        <ListItem key={project.project_id}>
                          <Paper
                            variant="outlined"
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "1rem 1.25rem",
                            }}
                          >
                            <Typography variant="body1">
                              {project.project_name}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <Typography variant="body1" color="textSecondary">
                                {project.user_name}
                              </Typography>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  handleViewProject(
                                    project.group_code,
                                    project.project_code
                                  )
                                }
                                sx={{ padding: 0 }}
                              >
                                <Icon
                                  name="viewIcon"
                                  color="primary"
                                  width="20"
                                  height="20"
                                />
                              </IconButton>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  handleEditProject(
                                    project.group_code,
                                    project.project_code
                                  )
                                }
                                sx={{ padding: 0 }}
                                disabled={project.permission_type !== "write"} // 조건에 따른 활성화
                              >
                                <Icon
                                  name="editIcon"
                                  color="primary"
                                  width="20px"
                                  height="20px"
                                />
                              </IconButton>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  handleSafeProject(
                                    project.group_code,
                                    project.project_code
                                  )
                                }
                                sx={{ padding: 0 }}
                                disabled={project.permission_type !== "write"} // 조건에 따른 활성화
                              >
                                <Icon
                                  name="shieldIcon"
                                  color="primary"
                                  width="20px"
                                  height="20px"
                                />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() =>
                                  handleDeleteConfirm(project.project_id)
                                }
                                sx={{ padding: 0 }}
                                disabled={
                                  !(
                                    user.login_id === project.login_id ||
                                    (isAdmin &&
                                      user.group_id === project.group_id)
                                  )
                                } // 조건에 따른 활성화
                              >
                                <Icon
                                  name="deleteIcon"
                                  color="error"
                                  width="20px"
                                  height="20px"
                                />
                              </IconButton>
                            </Box>
                          </Paper>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>

      {/* 신규 프로젝트 폼 */}
      <NewProjectDialog
        open={isFormOpen}
        onClose={handleCloseForm}
        onProjectAdded={handleProjectAdded}
      />
      {/* 프로젝트 삭제 확인 */}
      <ConfirmDialog
        open={deleteDialogOpen}
        onConfirm={handleDeleteProject}
        onCancel={handleCloseDeleteDialog}
        actionType={"선택한 프로젝트를 삭제"}
        extraSentence={"삭제 전 데이터베이스와 파일 백업을 권장합니다."}
        iconName={"deleteIcon"}
        iconColor={"error"}
        iconSize={"100px"}
      />
    </Grid>
  );
};

export default StatusMasterPage;
