import { useCallback } from "react";
import { axiosInstance } from "../../../api/axios";

export const useProjectApi = (groupCode, projectCode) => {
  const makeRequest = useCallback(
    (method, endpoint, data = null) => {
      const fullUrl = `/risk/${groupCode}/${projectCode}${endpoint}`;
      return axiosInstance[method](fullUrl, data);
    },
    [groupCode, projectCode]
  );

  return {
    get: (endpoint) => makeRequest("get", endpoint),
    post: (endpoint, data) => makeRequest("post", endpoint, data),
    put: (endpoint, data) => makeRequest("put", endpoint, data),
    delete: (endpoint) => makeRequest("delete", endpoint),
  };
};
